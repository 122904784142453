import styled, { keyframes } from 'styled-components';

export const waxfusionTheme = {
    background: "#eef5ef",
    darkBlue: "#03273c",
    lighterBlue: "#1c4157",
    primary: "#0a324a",
    gray: "#838181",
    offWhiteDarker: '#d4dcd5',
    offWhiteDarkest: '#b9bdb9'
}

export const theme2024 = {
    primary: "#8068ab",
    textMain: "white",
    offWhite: '#ebebeb',
    textSecondary: "#BCBCBC",
    textSecondaryDarker: "#9C9999",
    secondaryLight: "#2e2d2d",
    secondaryMedium: "#262525",
    secondaryDark: "#1c1c1b",
    secondaryDarker: "#161616",
    darkGrey: "#343a40",
    hoverColor: "rgba(99, 99, 99, 0.3)",
    backgroundDark: "#0c0d0d",
    secondary: "#D91E75",
    danger: "#dc3545",
    caution: "#ffc107",
    cautionOrange: "#c2810a",
    success: "#28a745",
}

export const PageWrapper2024 = styled.div`
    width: 100vw;
    max-width: 100%;
    min-height: 900px;
    margin-left: 0px; 
    margin-right: 0px;
    margin-top: ${props => props.profile ? '0px' : '70px'};
    //background-color: ${theme2024.backgroundDark};
    padding-bottom: 2em;
    padding-top: ${props => props.profile ? '0px' : '50px'};
    color: ${theme2024.textMain};
    word-wrap: break-word;
    overflow: hidden;

    background: rgb(156,207,225);
    background: -moz-radial-gradient(circle, rgba(156,207,225,1) 0%, rgba(116,178,209,1) 58%);
    background: -webkit-radial-gradient(circle, rgba(156,207,225,1) 0%, rgba(116,178,209,1) 58%);
    background: radial-gradient(circle, rgba(156,207,225,1) 0%, rgba(116,178,209,1) 58%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9ccfe1",endColorstr="#74b2d1",GradientType=1);

    @media (max-width: 1100px) {
        margin-top: ${props => props.profile ? '0px' : '45px'};
    }

    @media (max-width: 900px) {
        height: auto;
    }

`

export const PageBody2024 = styled.div`
    width: ${props => props.fullWidth ? '100%' : '900px'};
    min-height: 900px;
    margin-left: auto;
    margin-right: auto;
    //border-left: 0.5px solid white;
    //border-right: 0.5px solid white;
    max-width: 100%;
    overflow: hidden;

    @media (max-width: 1020px) {
        width: ${props => props.fullWidth ? '100%' : '700px'};
    }

    @media (max-width: 760px) {
        width: ${props => props.fullWidth ? '100%' : '620px'};
    }   
    
    @media (max-width: 630px) {
        width: ${props => props.fullWidth ? '100%' : '98%'};
    }       
`

export const FullWidthPageBody = styled.div`
    width: ${props => props.fullWidth ? '100%' : '900px'};
    min-height: 900px;
    margin-left: auto;
    margin-right: auto;
    //border-left: 0.5px solid white;
    //border-right: 0.5px solid white;
    max-width: 100%;
    overflow: hidden;

    @media (max-width: 1020px) {
        width: ${props => props.fullWidth ? '100%' : '700px'};
    }

    @media (max-width: 760px) {
        width: ${props => props.fullWidth ? '100%' : '620px'};
    }   
    
    @media (max-width: 630px) {
        width: ${props => props.fullWidth ? '100%' : '98%'};
    }       
`

export const Modal2024 = styled.div`
   
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 15011;
   
    width: 450px;
    max-width: 90%;
    height: auto;
    max-height: 70%;
    background-color: ${theme2024.secondaryDark};
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 15px;
    //box-shadow: 0px 0px 3px 1px whitesmoke;
    overflow-x: scroll;

    @media (max-width: 900px) {
        margin-top: 50px; 
    }
`

export const ModalContent2024 = styled.div`
    padding-top: 30px;
    color: ${theme2024.textMain};
    font-size: 14px;
    a{
        color: ${theme2024.primary}
    }
    

`

export const ModalErrorCont = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

    svg{
        width: 50px;
        height: 50px;
        fill: ${theme2024.danger};
    }
`
export const ModalSuccessCont = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

    svg{
        width: 50px;
        height: 50px;
        fill: ${theme2024.success};
    }
`

export const ModalOverlay2024 = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15010;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 80%;
    
`

export const HeaderCont2024 = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;

    h2 {
        color: ${waxfusionTheme.darkBlue};
        font-weight: 700;
        font-size: 28px;
        //font-style: italic;
    }

    h3 {
        color: ${waxfusionTheme.darkBlue};
        font-weight: 500;
        font-size: 16px;
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1em;
    }

    @media (max-width: 760px) {
        flex-wrap: wrap; // Add this line to allow items to wrap onto the next line
        flex-direction: column; // Stack child elements vertically
        align-items: stretch; // Stretch child elements to fill the width

        div {
            text-align: center;
            width: 100%; // Ensure each div takes the full width
            flex-basis: 100%; // Make each div take the full width available
            padding: 0.3em;
        }

        h3 {
            font-size: 14px;
        }        
    }
`

export const SwapCont = styled.div`
    position: relative;
    width: 420px;
    border: 1px solid #2e2d2d;
    border-radius: 10px;
    height: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: #1c1c1b;

    @media (max-width: 480px) {
        width: 98%;
    }     
`

export const SwapTopCont = styled.div`
    position: absolute;
    width: 100%;
    top: 0px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: right;
`

export const SwapFormTitle = styled.div`
    position: absolute;
    top: 5px;
    width: 100%;
    text-align: center;
    color: ${theme2024.primary};
`

export const RightAlignedContainer = styled.div`
    align-self: flex-end;
    //border: 1px solid white;
    padding-top: 4px;
    padding-right: 25px;
`;


export const SwapInputCont = styled.div`
    position: absolute;
    width: 90%;
    //margin-left: auto;
    //margin-right: auto;
    height: 150px;
    border-radius: 7px;
    background-color: #2e2d2d;
    top: 35px;
    left: 5%;
    padding: 15px;
`

export const SwapOutputCont = styled.div`
    position: absolute;
    width: 90%;
    //margin-left: auto;
    //margin-right: auto;
    height: 150px;
    border-radius: 7px;
    background-color: #2e2d2d;
    top: 240px;
    left: 5%;
    padding: 15px;
`

export const SubmitSwapButton = styled.button`
    position: absolute;
    width: 90%;
    //margin-left: auto;
    //margin-right: auto;
    height: 55px;
    border-radius: 7px;
    background-color: #2e2d2d;
    top: 410px;
    left: 5%;
    border: 1px solid #403f3f;
    color: ${theme2024.primary};

    :hover{
        background-color: rgba(99, 99, 99, 0.3);
        transition: background-color 0.3s;
    }    
`
export const SwapRow1 = styled.div`
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    font-weight: 400;
    color: ${theme2024.textSecondary}
`

export const SwapRow2 = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    font-weight: 400;
    color: ${theme2024.textMain}

    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`

export const SwapRowContractName = styled.div`
    height: 15px;
    width: 100%;
    text-align: right;
    font-size: 12px;
    font-weight: 300;
    color: ${theme2024.textSecondary}
    opacity: 80%;
`

export const SwapTokenDiv = styled.div`
    width: 30%;
    font-size: 14px;
    text-align: center;
`

export const SwapTokenAmountDiv = styled.div`
    width: 70%;
    font-size: 24px;
    text-align: right;

    @media (max-width: 420px) {
       font-size: 18px;
    }        

    @media (max-width: 375px) {
        font-size: 14px;
    }       

    input{
        background-color: inherit;
        text-align: inherit;
        
        :focus{
            outline: none;
        }
    }
`

export const SwapRow3 = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 300;
    color: ${theme2024.textSecondary}
    padding: 10px;   

    button{
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid ${theme2024.textSecondary};
        border-radius: 5px;
        padding: 5px;
        width: 70px;
        align-items: center;
        height: 100%;
        :hover{
            background-color: rgba(99, 99, 99, 0.3);
            transition: background-color 0.3s;
        }

        @media (max-width: 420px) {
            width: 50px;
            font-size: 10px;
        } 

    }


`

export const SelectTokenModalWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 15011;

    width: 420px;
    border: 1px solid #2e2d2d;
    border-radius: 10px;
    height: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: #1c1c1b;
    text-align: center;

    @media (max-width: 480px) {
        width: 98%;
    } 
`



export const SelectTokenHeaderCont = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    color: ${theme2024.textMain};
    font-weight: 400;
    padding: 10px;
    text-align: left;
    margin-bottom: 15px;
    //border-bottom: 1px solid ${theme2024.textSecondary};
`

export const SelectTokenRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    //border-bottom: 1px solid ${theme2024.textSecondary};
    font-size: 18px;

    img{
        width: 25px;
        height: 25px;
    }

    button{
        display: inherit;
        flex-wrap: wrap;
        //flex-direction: column;
        align-items: center;
        //justify-content: space-between;
        padding: 12px;
        width:100%;
        :hover{
            background-color: rgba(99, 99, 99, 0.3);
            transition: background-color 0.3s;
        }
    }
`
export const SelectTokenRowTokenName = styled.div`
    padding-left: 7px;
    font-size: 16px;
    font-weight: 300;
    //border: 1px solid green;

    span{
        color: ${theme2024.textSecondary};
        font-size: 12px;
        font-weight: 400;
    }

    @media (max-width: 480px) {
        font-size: 12px;
    } 

`

export const SelectTokenRowTokenAmount = styled.div`
    //width: 100%;
    flex-grow: 1;
    text-align: right;
    //border: 1px solid white;
    font-size: 18px;
    color: ${theme2024.textSecondary};
    //border: 1px solid green;

    @media (max-width: 480px) {
        font-size: 14px;
    }     
`

export const SelectLpTokenUnderlyingInfo = styled.div`
    width: 100%;
    font-size: 12px;
    text-align: left;
    padding-left: 10px;
`

export const SelectTokenModalInnerWrapper = styled.div`
   
   
    width: 100%;
    max-width: 100%;
    height: 80%;
    max-height: 80%;
    background-color: #1c1c1b;
    text-align: left;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    overflow-x: hidden;

    -ms-overflow-style: scrollbar;  /* IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: ${theme2024.primary} black; 

    ::-webkit-scrollbar{
        height: 4px;
    }

    ::-webkit-scrollbar-thumb{

        background: ${theme2024.primary};
    }

    ::-webkit-scrollbar-track{

        background: #dfdcdc40;
    }

    a{
        color: ${theme2024.primary};
    }

`

export const FoldersContainer2024 = styled.div`
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    clear: both;
`
export const FolderTab2024 = styled.button`
    display: inline-block;
    width: 175px;
    border-bottom: ${props => props.selected == true ? `2px solid ${waxfusionTheme.darkBlue}` : 'none'};
    height: 30px;
    color: ${props => props.selected == true ? waxfusionTheme.darkBlue : props.color ? props.color : 'white' };
    font-weight: ${props => props.fontWeight ? props.fontWeight : 500};
    letter-spacing: ${props => props.letterSpacing && props.letterSpacing};
    text-shadow: ${props => props.selected == true ? 'none' : props.textShadow ? props.textShadow : '2px 2px 4px rgba(0, 0, 0, 0.4)' };
    transition: color 0.3s;

    :hover{
        color: ${waxfusionTheme.darkBlue};
        text-shadow: none;
    }

    @media (max-width: 768px) {
        min-width: 20%;
        width: 25%;
    }
`

export const RentalFolderTab = styled.button`
    display: inline-block;
    width: 175px;
    border-bottom: ${props => props.selected == true ? `2px solid ${waxfusionTheme.darkBlue}` : 'none'};
    height: 30px;
    color: ${props => props.selected == true ? waxfusionTheme.darkBlue : theme2024.darkGrey };
    font-weight: 500;

    :hover{
        color: ${waxfusionTheme.darkBlue};
    }

    @media (max-width: 768px) {
        min-width: 20%;
        width: 25%;
    }
`

export const HomepageWrapper2024 = styled.div`
    width: 100vw;
    max-width: 100%;
    min-height: 900px;
    margin-left: 0px; 
    margin-right: 0px;
    margin-top: 0px;
    background-color: ${waxfusionTheme.background};
    padding-bottom: 0em;
    padding-top: 0px;
    color: ${waxfusionTheme.primary};
    word-wrap: break-word;
    overflow: hidden;
    margin-top: 110px;

    @media(max-width: 1100px){
        margin-top: 45px;
    }    

    @media (max-width: 900px) {
        height: auto;
    }

`


export const HeroWrapper2024 = styled.div`
    width: 100%;
    display: flex;
    height: 530px;
    
    background: rgb(156,207,225);
    background: -moz-radial-gradient(circle, rgba(156,207,225,1) 0%, rgba(116,178,209,1) 58%);
    background: -webkit-radial-gradient(circle, rgba(156,207,225,1) 0%, rgba(116,178,209,1) 58%);
    background: radial-gradient(circle, rgba(156,207,225,1) 0%, rgba(116,178,209,1) 58%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9ccfe1",endColorstr="#74b2d1",GradientType=1);

    
    background-size: cover;
    background-position: center;    

    @media (max-width: 940px) {
        height: auto;
        flex-direction: column;
    }     

    @media (max-width: 1020px) {
        justify-content: space-between;
    }    
`

export const HeroLeft2024 = styled.div`
    height: 100%;
    width: 60%;

    @media (max-width: 1020px) {

    }  

    @media (max-width: 940px) {
        width: 100%;
        height: 480px;
    } 
   
`

export const HeroLeftTop2024 = styled.div`
    width: 100%;
    height: 40%;
    font-size: 112px;
    font-weight: 700;
    display: flex;
    align-items: flex-end; 
    color: ${waxfusionTheme.primary};
    padding-left: 30px;
    padding-bottom: 0px;

    @media (max-width: 940px) {
        justify-content: center;
        padding-left: 0px;
      }     
      
      @media (max-width: 560px) {
        font-size: 96px;
    }     
    
    @media (max-width: 480px) {
        font-size: 72px;
      }    
`


export const HeroLeftMiddle2024 = styled.div`
    width: 100%;
    height: 20%;
    font-size: 36px;
    font-weight: 400;
    color: ${waxfusionTheme.primary};
    padding-left: 40px;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 20px;
    
    @media (max-width: 940px) {
        text-align: center;
        padding-left: 0px;
      }      

    @media (max-width: 560px) {
        font-size: 30px;
    }    
    
    @media (max-width: 480px) {
        //font-size: 24px;
      }    

`;


export const HeroLeftAnimation = styled.div`
    padding-top: 0px;
    line-height: 62px;
    animation: textAnimation 4s infinite;
        
    @keyframes textAnimation {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }
        15% {
            transform: translateX(0%);
            opacity: 1;
        }
        40% {
            transform: translateX(0%);
            opacity: 1;
        }
        55% {
            transform: translateX(0%);
            opacity: 1;
        }
        70% {
            transform: translateX(0%);
            opacity: 1;
        } 
        85% {
            transform: translateX(0%);
            opacity: 1;
        }         
        100% {
            transform: translateX(-100%);
            opacity: 0;
        }
    }

    @media (max-width: 940px) {
        justify-content: center;
        text-align: center;
      }          
`

export const HeroLeftBottom2024 = styled.div`
    display: flex;
    gap: 15px;
    width: 100%;
    height: 20%;
    color: white;
    font-size: 20px;
    font-weight: 400;
    padding: 1em;
    padding-left: 40px;

    @media (max-width: 940px) {
        text-align: center;
        justify-content: center;
        padding-right: 2em;
      }      
`

export const Hero2024Button = styled.button`
    width: 160px;
    padding: 1em;
    border: 1px solid white;
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme2024.textMain};
    background-color: ${props => props.primary ? waxfusionTheme.darkBlue : 'transparent'};
    height: 50px;

    :hover{
        border: 1px solid ${theme2024.textMain};
        padding: 1.1em;
        transform: scale(1.05);
        transition: transform 0.2s;
    }

    @media (max-width: 480px) {
        font-size: 12px;
        width: 120px;
      }     
      
      @media (max-width: 430px) {
        margin-top: 20px;
  }        

`

export const HeroRight2024 = styled.div`
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img{
        max-width: 80%;
        max-height: 80%;
        opacity: 90%;
    }

    @media (max-width: 1020px) {
        width: 355px;
    } 

    @media (max-width: 940px) {
        width: 100%;
        height: 150px;
        display: flex;
        flex-direction: column;
    }           
`



export const InfoIsLoadingBlur = styled.div`
@keyframes loadingAnimation {
    0% {
      background-position: -150px 0;
    }
    40% {
        background-position: 150px 0;
      }    
    100% {
      background-position: 150px 0;
    }
  }

  width: ${props => props.width ? props.width : '150px'};
  height: 12px;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(200, 200, 200, 0.5), rgba(150, 150, 150, 0.7), rgba(200, 200, 200, 0.5));
  background-size: 150px 20px;
  animation: loadingAnimation 2s linear infinite;
`


export const CheckboxContainer = styled.div`

  font-size: 14px;

  img{
    width: 20px;
    height: 20px;
  }

  div{
    width: 20px;
    height: 20px;
    border: 1px solid ${waxfusionTheme.darkBlue};
    border-radius: 3px;
  }

  :hover{
    cursor: pointer;
  }
`;


export const SwapDetailsCont = styled.div`
    position: absolute;
    //border: 1px solid white;
    top: 340px;
    height: 70px;
    width: 90%;
    left: 5%;
    font-size: 12px;
    padding-top: 10px;
`

export const SwapDetailsRow = styled.div`
    display: flex;
    width: 100%;
`
export const SwapDetailsName = styled.div`
    width: ${props => props.row == "Rate" ? "20%" : "40%"};
    color: ${theme2024.textSecondary};
    text-align: left;
`
export const SwapDetailsValue = styled.div`
    width: ${props => props.row == "Rate" ? "80%" : "60%"};
    color: ${theme2024.textMain};
    display: flex;
    justify-content: right;
`

export const CreateLockButton = styled.button`
    width: 90%;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    height: 55px;
    border-radius: 7px;
    background-color: #2e2d2d;
    border: 1px solid #403f3f;
    color: ${theme2024.primary};
    margin-top: 15px;
    transition: background-color 0.5s;

    :hover{
        background-color: rgba(99, 99, 99, 0.5);
        
    }    
`

export const LpLockCard2024 = styled.div`
    position: relative;
    width: 160px;
    height: 180px;
    border-radius: 10px;
    background-color: #101010; //${theme2024.secondaryDarker};
    box-shadow: 1px 1px 3px 0px ${theme2024.secondaryLight};

    @media (max-width: 420px) {
        width: 140px;
        height: 160px;
      }      

    :hover{
        cursor: pointer;
        transform: scale(1.05);
        transition: transform 0.2s;
        //border: 1px solid white;
        box-shadow: 0px 0px 3px 0px ${theme2024.textMain};
    }
`


export const LpLockCardAmount = styled.div`
    position: absolute;
    top: 115px;
    width: 100%;
    padding-left: 10px;
    font-weight: 600;
    font-size: 12px;
    color: ${theme2024.primary};
    text-align: left;

    @media (max-width: 420px) {
        top: 95px;
      }      
`
