import { useState } from "react";
import { DAPP_CONTRACT, TESTNET, mainnetApiList, testnetApiList } from "../../data/config";
import axios from 'axios';

export const useGetRewardPool = () => {
    
    const [r, setR] = useState([]);
    const [rIsLoading, setRIsLoading] = useState(true);

    let apiList = TESTNET ? testnetApiList : mainnetApiList;

    const getRewardPool = async () => {
        setRIsLoading(true);

        for (const api of apiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "rewards",
                    scope: DAPP_CONTRACT,
                    code: DAPP_CONTRACT,
                    limit: 1,
                    json: true,
                });
                
                if (res.data.rows) {
                    setR(res.data.rows[0]);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }
        setRIsLoading(false);
    }

    return [r, getRewardPool, rIsLoading]
}
