import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { Body, MainWrapper } from './Styles';
import { useStateContext } from './contexts/ContextProvider';
import { Footer } from './components';
import LoadingPage from './components/LoadingPage';
import { sessionKit } from './data/wharfkit';
import { GlobalStyle } from './Styles';
import Navbar2024 from './components/Navbar2024';
import Stake from './pages/Stake';
import Rent from './pages/Rent';
import LongTermRent from './pages/LongTermRent';
import RedirectDiscord from './components/Discord';
import Powerup from './pages/Powerup';




//General
const HomePage2024 = lazy(() => import ('./pages/HomePage2024'));
const Status = lazy(() => import ('./pages/Status'));
const NotFound = lazy(() => import('./components/NotFound'));

const App = () => {
    const {
        wharfSession,
        setWharfSession
    } = useStateContext();

    let session

    async function restoreSession() {
        session = await sessionKit.restore()
        setWharfSession(session)
      }

useEffect(() => {
    restoreSession()
}, [])

    return (
    <div>
        <GlobalStyle />
        <BrowserRouter>
            <MainWrapper>

            <div className={`min-h-screen w-full flex-2`}>

                    <Navbar2024 />
                    <Body>
              
            <div>

                <Suspense fallback={<LoadingPage />}>
                <Routes>

                    {/* Home */}

                    <Route path="/" element={<HomePage2024 />} />
                    <Route path="/stake" element={<Stake />} />
                    <Route path="/rent" element={<Rent />} />
                    <Route path="/long-term-rent" element={<LongTermRent />} />
                    <Route path="/powerup" element={<Powerup />} />
                    <Route path="/status" element={<Status />} />

                    <Route path="/discord" element={<RedirectDiscord />} />


                    {/* 404 NOT FOUND CATCHALL */}

                    <Route path="*" element={<NotFound />} />

                </Routes>
                </Suspense>
            </div>
            </Body>
            <Footer />
            </div>

            </MainWrapper>       
        </BrowserRouter>
    </div>
  )
}

export default App