import React, { createContext, useContext, useState, useEffect } from 'react';
import { useGetFusionState } from '../components/CustomHooks/useGetFusionState';
import { useGetUserStake } from '../components/CustomHooks/useGetUserStake';
import { isLoggedIn } from '../data/functions/wallet_functions';
import { useGetTokenBalsFromLightApi } from '../components/CustomHooks/useGetTokenBalsFromLightApi';

const StateContext = createContext();

export const ContextProvider = ({ children }) => {

    const [refresh, setRefresh] = useState(false);
    const [fusionState, getFusionState, stateIsLoading] = useGetFusionState();  
    const [userStake, getUserStake, stakeIsLoading] = useGetUserStake();
    const [tokenBalances, getTokenBalances, balancesAreLoading] = useGetTokenBalsFromLightApi();
    
    useEffect(() => {
        let isMounted = true;

        if(isMounted){
            getFusionState();
        }

        const interval = setInterval(getFusionState, 30000);

        return () => {
            isMounted = false;
            clearInterval(interval); 
        }        
      }, [refresh]);   

      useEffect(() => {
        let isMounted = true;

        if(isMounted && isLoggedIn()){
            getUserStake();
            getTokenBalances();
        }

        const interval = setInterval(getFusionState, 60000);

        return () => {
            isMounted = false;
            clearInterval(interval); 
        }        
      }, [refresh]);         
    

    const [wharfSession, setWharfSession] = useState(null);
    const [activeMenu, setActiveMenu] = useState(false);
    const [screenSize, setScreenSize] = useState(undefined);
    const [currentUser, setCurrentUser] = useState('');
    const [currentNavbarLocation, setCurrentNavbarLocation] = useState("");
    const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);

    const [timestamp, setTimestamp] = useState('');

    // Navbar Display
    const [waxBalance, setWaxBalance] = useState('');
    const [loading, setLoading] = useState(true);

    const [showTxModal, setShowTxModal] = useState(false);
    const [txModalText, setTxModalText] = useState("");
    const [txIsLoading, setTxIsLoading] = useState(false);

    const setEpochTime = (e) => {
        let date      = new Date(e.target.value);
        let epochTime = date.getTime() / 1000;
        setTimestamp(epochTime);

    }

    return (
        <StateContext.Provider
            value={{ 
                activeMenu,
                setActiveMenu,
                screenSize,
                setScreenSize,
                currentUser,
                setCurrentUser,
                timestamp,
                setTimestamp,
                setEpochTime,
                waxBalance,
                setWaxBalance,
                loading,
                setLoading,
                currentNavbarLocation,
                setCurrentNavbarLocation,
                mobileNavIsOpen,
                setMobileNavIsOpen,
                wharfSession,
                setWharfSession,
                fusionState, 
                getFusionState, 
                stateIsLoading,
                userStake, 
                stakeIsLoading,
                refresh,
                setRefresh,
                tokenBalances,
                balancesAreLoading,
                showTxModal,
                setShowTxModal,
                txIsLoading,
                setTxIsLoading,
                txModalText,
                setTxModalText
             }}
        >
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext);