import { useCallback, useRef, useState } from "react";
import axios from 'axios';
import { currentUsername } from "../../data/config";

const makeMemo = (memo) => {
    return memo.split("<receiver>")[0] + currentUsername + memo.split("<receiver>")[1]
}

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  
  export const useGetAlcorSwapRate = () => {
    const [swapRate, setSwapRate] = useState([]);
    const [swapRateIsLoading, setSwapRateIsLoading] = useState(false);
    const lastUpdateRef = useRef(Date.now()); 
  
    const getSwapRate = async (inputAmount, outputAmount, inputToken, outputToken, method, setInputAmount, setOutputAmount, setMinReceived, setMemo, setPriceImpact, status, setStatus) => {
      const now = Number(Date.now());
  
      if (now - lastUpdateRef.current < 1900) {
        return;
      }
  
      setSwapRateIsLoading(true);
  
      let url_string;

      if(method == "EXACT_INPUT" && (inputAmount == null || inputToken == null || inputAmount == 0 || inputAmount.length == 0 )){
        console.log("exact input but no data")
      }

      if(method == "EXACT_OUTPUT" && (outputAmount == null || outputToken == null || outputAmount.length == 0 )){
        console.log("exact output but no data")
      }      

      let input_string = inputToken.currency.toLowerCase() + "-" + inputToken.contract.toLowerCase();
      let output_string = "wax-eosio.token";  

      if(!inputToken || !outputToken || !inputToken.contract || !outputToken.contract || (inputAmount == null && outputAmount == null) || (inputAmount.length == 0 && outputAmount.length == 0)){
        console.log("nothing to do")
        return
      }

      if(method == "EXACT_INPUT"){
        url_string = `https://wax.alcor.exchange/api/v2/swapRouter/getRoute?trade_type=EXACT_INPUT&input=${input_string}&output=${output_string}&amount=${Number(inputAmount).toFixed(Number(inputToken.decimals))}&slippage=0.50&maxHops=2`
      }

      else if(method == "EXACT_OUTPUT"){
        url_string = `https://wax.alcor.exchange/api/v2/swapRouter/getRoute?trade_type=EXACT_OUTPUT&input=${input_string}&output=${output_string}&amount=${Number(outputAmount).toFixed(8)}&slippage=0.50&maxHops=2`
      }

      else{
        console.log("error: no swap method set")
        return
      }
  
      try {
        const res = await axios.get(url_string, {});
  
        if (res) {
          setSwapRate(res);
        
          if(method == "EXACT_INPUT"){
            setOutputAmount(res.data.output)
          }
          else if(method == "EXACT_OUTPUT"){
            setInputAmount(res.data.input)
          }
          
          setMemo(makeMemo(res.data.memo))
          setPriceImpact(res.data.priceImpact)
          setMinReceived(res.data.minReceived)
          setStatus(200)
        }
      } catch (error) {
        if(error.message.includes('403')){
            setStatus(403)
        }
        console.log(error);
      } finally {
        setSwapRateIsLoading(false);
        lastUpdateRef.current = now;
      }
    };
  
    const debouncedGetSwapRate = useCallback(debounce(getSwapRate, 1000), []);
  
    return [swapRate, debouncedGetSwapRate, swapRateIsLoading];
  };