import { useState } from "react";
import { DAPP_CONTRACT, TESTNET, currentUsername, mainnetApiList, testnetApiList } from "../../data/config";
import axios from 'axios';
import { isLoggedIn } from "../../data/functions/wallet_functions";

export const useGetUserStake = () => {
    
    const [userStake, setUserStake] = useState([]);
    const [stakeIsLoading, setStakeIsLoading] = useState(true);

    let apiList = TESTNET ? testnetApiList : mainnetApiList;

    const getUserStake = async () => {
        setStakeIsLoading(true);

        if(isLoggedIn()){
            for (const api of apiList) {
                try {
                    const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                        table: "stakers",
                        scope: DAPP_CONTRACT,
                        code: DAPP_CONTRACT,
                        lower_bound: currentUsername,
                        upper_bound: currentUsername,
                        limit: 1,
                        json: true,
                    });
                    
                    if (res.data.rows[0]) {
                        setUserStake(res.data.rows[0]);
                        break;
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }

        setStakeIsLoading(false);
    }

    return [userStake, getUserStake, stakeIsLoading]
}
