import { useState } from "react";
import { TESTNET, currentUsername, mainnetApiList, testnetApiList } from "../../data/config";
import axios from 'axios';

export const useGetAccountResources = () => {
    
    const [resources, setResources] = useState([]);
    const [resourcesAreLoading, setResourcesAreLoading] = useState(true);

    let apiList = TESTNET ? testnetApiList : mainnetApiList;

    const getResources = async () => {

        setResourcesAreLoading(true);

        for (const api of apiList) {

            try {
                const res = await axios.post(`${api}/v1/chain/get_account`, {
                    account_name: currentUsername,
                    json: true,
                });

                if (res.data) {
                    console.log('get_account:')
                    console.log(res.data)
                    setResources(res.data);
                    break;
                }
            } catch (error) {
                console.log(`Failed on API endpoint ${api}. Error: ${error}`);
            }
        }
        
        setResourcesAreLoading(false);
    }

    return [resources, getResources, resourcesAreLoading]
}
