import { useState } from "react";
import { DAPP_CONTRACT, TESTNET, mainnetApiList, testnetApiList } from "../../data/config";
import axios from 'axios';

export const useGetCurrentEpochs = () => {
    
    const [epochs, setEpochs] = useState([]);
    const [loading, setLoading] = useState(true);

    let apiList = TESTNET ? testnetApiList : mainnetApiList;

    const getEpochs = async (epoch_ids) => {
        let epochsArray = [];
        setLoading(true);

            let foundData = false;
            for (const api of apiList) {

                try {
                    const res = await axios.post(`${api}/v1/chain/get_table_rows`, {
                        table: "epochs",
                        scope: DAPP_CONTRACT,
                        code: DAPP_CONTRACT,
                        lower_bound: epoch_ids[2],
                        upper_bound: epoch_ids[0],
                        limit: 3,
                        json: true,
                    });

                    if (res.data.rows && res.data.rows.length > 0) {
                        epochsArray.push(...res.data.rows);
                        foundData = true;
                    }
                } catch (error) {
                    console.log(error);
                }
                if (foundData) break;
            }
            setEpochs(epochsArray)
        setLoading(false);
    }

    return [epochs, getEpochs, loading]
}
