import { SessionKit } from "@wharfkit/session"
import { WebRenderer } from "@wharfkit/web-renderer"
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor"
import { WalletPluginCloudWallet } from "@wharfkit/wallet-plugin-cloudwallet"
import { WalletPluginScatter } from "@wharfkit/wallet-plugin-scatter"
import { WalletPluginWombat } from "@wharfkit/wallet-plugin-wombat"
import { APP_NAME, TESTNET, currentSelectedApi, mainnetChain, testnetChain, userSelectedAnApi } from "./config"

const webRenderer = new WebRenderer({minimal: true})

export const sessionKit = new SessionKit({
    appName: APP_NAME,
    chains: [   
      {
        id: TESTNET ? testnetChain.chainId : mainnetChain.chainId,
        url: (userSelectedAnApi == null || !userSelectedAnApi) ? TESTNET ? 
          `${testnetChain.rpcEndpoints[0]?.protocol}://${testnetChain.rpcEndpoints[0]?.host}` 
          : 
          `${mainnetChain.rpcEndpoints[0]?.protocol}://${mainnetChain.rpcEndpoints[0]?.host}` 
          :
        currentSelectedApi
      },      
    ],
    ui: webRenderer,
    walletPlugins: [
        new WalletPluginAnchor(), 
        new WalletPluginCloudWallet(),
        new WalletPluginScatter(),
        new WalletPluginWombat()
    ],
  })


let session

export const logInWithWharfkit = async (setCurrentUser, setWharfSession) => {
    const response = await sessionKit.login()
    session = response.session
    let this_user = session.actor.toString();
    let wallet_provider = session.walletPlugin.id;
    setCurrentUser(this_user);
    localStorage.setItem("waxAccount", this_user);
    localStorage.setItem("walletProvider", wallet_provider);
    setWharfSession(session)
    location.reload();
}

export const logOutWharfkit = async (setCurrentUser, setWharfSession) => {
    await sessionKit.logout()
    session = undefined
    setCurrentUser("");
    localStorage.removeItem("waxAccount");
    localStorage.removeItem("walletProvider");
    setWharfSession(null)
    location.reload();
}

export const switchWharfAccount = async (newAccount, newPermission, newWalletProvider, setCurrentUser, setWharfSession) => {
    const existingSessions =
      JSON.parse(localStorage.getItem("wharf--sessions")) || [];

    const foundSession = existingSessions.find(
      (session) => session.actor === newAccount && session.permission === newPermission  && session.walletPlugin.id === newWalletProvider
    );

    if (!foundSession) return;

    localStorage.setItem("wharf--session", JSON.stringify(foundSession));

    localStorage.setItem("waxAccount", newAccount);
    localStorage.setItem("walletProvider", newWalletProvider);

    setCurrentUser(newAccount);
    setWharfSession(foundSession)

    location.reload();
}

export const removeWharfSession = (accountToRemove, permissionToRemove, walletProviderToRemove, setSessions) => {
    const existingSessions =
      JSON.parse(localStorage.getItem("wharf--sessions")) || [];

    const sessionIndex = existingSessions.findIndex(
      (session) => session.actor === accountToRemove && session.permission === permissionToRemove && session.walletPlugin.id === walletProviderToRemove
    );

    if (sessionIndex !== -1) {
      existingSessions.splice(sessionIndex, 1);
      localStorage.setItem("wharf--sessions", JSON.stringify(existingSessions));
    }

    fetchSessionsFromLocalStorage(setSessions)
}

export const fetchSessionsFromLocalStorage = (setSessions) => {
    const storedSessions = JSON.parse(localStorage.getItem("wharf--sessions")) || [];
    setSessions(storedSessions);
  };