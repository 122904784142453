import { useState } from "react";
import { TESTNET, mainnetApiList, testnetApiList } from "../../data/config";
import axios from 'axios';

export const useGetChainInfo = () => {
    
    const [chainInfo, setChainInfo] = useState([]);
    const [chainInfoIsLoading, setChainInfoIsLoading] = useState(true);

    let apiList = TESTNET ? testnetApiList : mainnetApiList;

    const getChainInfo = async () => {

        setChainInfoIsLoading(true);

        for (const api of apiList) {

            try {
                const res = await axios.get(`${api}/v1/chain/get_info`, {

                });

                if (res.data) {
                    setChainInfo(res.data)
                    break;
                }
            } catch (error) {
                console.log(`Failed on API endpoint ${api}. Error: ${error}`);
            }
        }
        
        setChainInfoIsLoading(false);
    }

    return [chainInfo, getChainInfo, chainInfoIsLoading]
}
