import { useState } from "react";
import { TESTNET, currentUsername, mainnetApiList, testnetApiList } from "../../data/config";
import axios from 'axios';

export const useGetPowerupState = () => {
    
    const [powerupState, setPowerupState] = useState([]);
    const [powerupStateIsLoading, setPowerupStateIsLoading] = useState(true);

    const getPowerupState = async () => {

        setPowerupStateIsLoading(true);
        let apiList = TESTNET ? testnetApiList : mainnetApiList;

        for (const api of apiList) {

            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`, {
                    code: "eosio",
                    scope: "0",
                    table: "powup.state",
                    limit: 1,
                    json: true,
                });

                if (res.data.rows) {
                    setPowerupState(res.data.rows[0]);
                    break;
                }
            } catch (error) {
                console.log(`Failed on API endpoint ${api}. Error: ${error}`);
            }
        }
        
        setPowerupStateIsLoading(false);
    }

    return [powerupState, getPowerupState, powerupStateIsLoading]
}
