import styled, { createGlobalStyle } from 'styled-components'
import { waxfusionTheme } from './Styles2024';

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  body {
    font-family: 'Exo', sans-serif;
  }
`

export const theme = {
  main: "#1F2A44",
  mainDarker: "#151f36",
  secondary: "#01B1AE",
  secondaryHover:"#1f9290",
  textMain: "white",
  textSecondary: "#BCBCBC",
  gradientLeft: "#5BBBEB",
  gradientRight: "#F8BBEB",
};

export const currentTheme = {
    main: "#47158e",
    secondary: "#c30000",
    secondaryHover:"#dc0bb6",
    textMain: "white",
  };


export const MainWrapper = styled.div`
  position: relative;
  display: flex;
  background-color: ${waxfusionTheme.background};
  

`

export const Body = styled.div`
    min-height: 80vh;
    padding: 0px;
    background-color: ${waxfusionTheme.background};
`

export const CloseModalButton = styled.button`
  width: 200px;
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 900;
  border: 1px solid white;
  margin-top: 1em;

  @media (max-width: 500px) {
      font-size: 14px;
  }

  color: ${currentTheme.textMain};

  :hover{
      background-color: ${currentTheme.textMain};
      color: ${currentTheme.main};
      transition: background-color 1s;
  }
`

export const MainButton = styled.button`

    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 10px;
    color: ${theme.textMain};
    background-color: ${theme.secondary};

    :hover{
        background-color: ${theme.secondaryHover};
        transition: background-color 1s;
    }
`

export const FoldersContainer = styled.div`
    width: 100%;
    margin-top: 15px;
`

export const FoldersRow = styled.div`
    width: 100%;
    height: 30px;
    font-size: 14px;
    @media (max-width: 768px) {
        font-size: 12px;
    }

    @media (max-width: 450px) {
        font-size: 10px;
    }

    @media (max-width: 375px) {
        font-size: 8px;
    }
`


export const Slider = styled.div`
  width: 50px;
  height: 25px;
  background-color: ${(props) => (props.toggled ? theme.secondary : theme.textSecondary)};
  border-radius: 25px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.5s ease;
`;

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.5s ease;
  left: ${(props) => (props.toggled ? 'calc(100% - 12px)' : '12px')};
`;

