import { useState } from "react";
import { ContractKit } from "@wharfkit/contract";
import { APIClient } from "@wharfkit/antelope";
import { DAPP_CONTRACT, TESTNET, mainnetApiList, testnetApiList } from "../../data/config";
import { isLoggedIn } from "../../data/functions/wallet_functions";

const apiList = TESTNET ? testnetApiList : mainnetApiList;

const contractKit = new ContractKit({
    client: new APIClient({ url: apiList[0] }),
  });

export const useGetClaimableRewards = () => {
    
    const [loading, setLoading] = useState(true);
    const [claimableRewards, setClaimableRewards] = useState(0);

    const getClaimableRewards = async (user) => {
        setLoading(true);

        if(isLoggedIn()){
            try {
                const contract = await contractKit.load(DAPP_CONTRACT);
          
                const result = await contract.readonly("showreward", {
                  user: user
                });
          
                setClaimableRewards(parseFloat(result));
              } catch (e) {
                console.log(`error fetching user rewards: ${e}`);
              }   
        }

        setLoading(false);
    }

    return [claimableRewards, getClaimableRewards, loading]
}
