import React, { useEffect, useRef, useState } from "react";
import config from "../data/config.json";
import header_logo from "../data/basic_logo.svg";
import white_logo from "../data/basic_logo_white.svg";
import { useStateContext } from "../contexts/ContextProvider";
import {
  Spinner,
  SpinnerBlue,
  SpinnerGreen,
  SpinnerRed,
} from "./LeftDiv";
import {
  currentWebsiteURL,
  currentUsername,
  TOKEN_CONTRACT,
} from "../data/config";

import { isLoggedIn } from "../data/functions/wallet_functions";
import { fetchSessionsFromLocalStorage, logInWithWharfkit, logOutWharfkit, removeWharfSession, switchWharfAccount } from "../data/wharfkit";
import cloud_logo from "../data/mycloudwallet.png";
import anchor_logo from "../data/anchor.svg";
import wombat_logo from "../data/wombat_logo.png";
import scatter_logo from "../data/scatter_logo.png";
import { FaTrashAlt } from "react-icons/fa";
import { Modal2024, ModalContent2024, ModalOverlay2024, theme2024, waxfusionTheme } from "../Styles2024";
import { CloseDivXIconWrapper, LargeNav2024BottomWrapper, LargeNav2024LinkButton, LargeNav2024LinksWrapper, LargeNav2024TopItem, LargeNav2024TopWrapper, LargeNavDropDownInnerWrapper, LargeNavLinkDropDown, LargeNavLinkDropDownRow, LargeNavSocialIconsDiv, LargeNavTopLeftWrapper, LargeNavTopRightWrapper, LargeNavWrapper2024, LargeNavbar2024LoginButton, SmallNavBottomWrapper, SmallNavDropDown, SmallNavLoginButtonWrapper, SmallNavSocialIconsDiv, SmallNavTopLeftWrapper, SmallNavTopRightWrapper, SmallNavWrapper2024, SmallNavbar2024LoginButton, SmallScreenWalletsWrapper, UserDropDown, UserDropDownBalancesCont, UserDropDownBuyWaxDaoButton, UserDropDownLinkButton, UserDropDownTopLeft, UserDropDownTopSection, UserDropDownWalletsSeparator, UserDropDownWalletsTitleCont, UserDrowDownInnerScrollWrapper } from "../data/css/NavbarStyles2024";
import { burger_menu_icon, down_arrow_svg, x_close_svg } from "../data/constants";
import NumberFormat from "react-number-format";
import { WaxAccountButton, WaxAccountGap, WaxAccountInnerCont, WaxAccountLeft, WaxAccountMiddle, WaxAccountRight } from "../data/css/SidebarStyles";
import { cpu_svg, discord_svg, docs_svg, medium_svg, status_svg, telegram_svg, twitter_svg, water_svg } from "../data/svgs";
import { getSWaxPerLSWax } from "../data/functions/fusion_functions";


const showWaxAndWaxdaoBalance = (tokens, loading, userStake, stakeIsLoading) => {
  const waxColor = theme2024.darkGrey;
  const waxdaoColor = theme2024.darkGrey;

  if(loading || stakeIsLoading) return (
    <span>
      <h4 style={{color: waxColor}}>0 WAX</h4>
      <h4 style={{color: waxColor}}>0 SWAX</h4>
      <h4 style={{color: waxdaoColor}}>0 LSWAX</h4>
    </span>
  );

  let waxBalance = 0;
  let sWaxBalance = 0;  
  let lsWaxBalance = 0;
  
  if (userStake?.swax_balance) {
    sWaxBalance = Number(userStake?.swax_balance.split(" ")[0]).toFixed(2);
  }
  

  tokens.forEach(t => {
    if (t.currency === "WAX" && t.contract === "eosio.token") {
      waxBalance = Number(t.amount).toFixed(2);
    } else if (t.currency === "LSWAX" && t.contract === TOKEN_CONTRACT) {
      lsWaxBalance = Number(t.amount).toFixed(2);
    }
  });

  return (
    <span>
      <h4 style={{color: waxColor}}><NumberFormat displayType='text' thousandSeparator={true} value={waxBalance} /> WAX</h4>
      <h4 style={{color: waxColor}}><NumberFormat displayType='text' thousandSeparator={true} value={sWaxBalance} /> SWAX</h4>
      <h4 style={{color: waxdaoColor}}><NumberFormat displayType='text' thousandSeparator={true} value={lsWaxBalance} /> LSWAX</h4>
    </span>
  );
};

const getWalletLogo = (session) => {
  if(session.walletPlugin.id == "anchor") return anchor_logo
  else if(session.walletPlugin.id == "cloudwallet") return cloud_logo
  else if(session.walletPlugin.id == "wombat") return wombat_logo
  else if(session.walletPlugin.id == "scatter") return scatter_logo
}

const handleNavBlur = (e, setSelectedTab) => {
  if(e.relatedTarget && e.relatedTarget.href && e.relatedTarget.href.indexOf("http") > -1){
    return;
  }
  setSelectedTab('')
}

const Navbar2024 = () => {
  const {
    setCurrentUser,
    setWharfSession,
    fusionState, 
    stateIsLoading,
    userStake, 
    stakeIsLoading,
    tokenBalances,
    balancesAreLoading
  } = useStateContext();

  const [sessions, setSessions] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [showUserDropDown, setShowUserDropDown] = useState(false);
  const [showSmallScreenDropDown, setShowSmallScreenDropDown] = useState(false);
  const [showWallets, setShowWallets] = useState(false);

  const [screenSize, setScreenSize] = useState(0);
  const screenSizeRef = useRef(window.innerWidth);

  useEffect(() => {
    fetchSessionsFromLocalStorage(setSessions);
  }, []);  


  useEffect(() => {

    if (currentUsername && isLoggedIn()) {
      setCurrentUser(currentUsername);
    } else {
      console.log("You are not logged in");
    }
  }, [currentUsername]);

  useEffect(() => {
    const handleResize = () => {
      const old_width = screenSizeRef.current;
      const new_width = window.innerWidth;
      const break_point = Number(1100);

      if ((old_width < break_point && new_width >= break_point) || (old_width > break_point && new_width <= break_point)) {
        setSelectedTab("")
        setShowSmallScreenDropDown(false)
        setShowUserDropDown(false)
        setShowWallets(false)
      }

      screenSizeRef.current = new_width;
      setScreenSize(new_width);      
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  return (
    <div className="wrapper">

    <LargeNavWrapper2024>
        <LargeNav2024TopWrapper>
            <LargeNavTopLeftWrapper>
                <LargeNav2024TopItem>
                    LSWAX BACKING: {Number(getSWaxPerLSWax(fusionState, stateIsLoading)).toFixed(4)}{" WAX"}
                </LargeNav2024TopItem>
                <LargeNav2024TopItem>
                    {/* CIRCULATING SUPPLY: {formatNumber(Number(supply))} */}
                </LargeNav2024TopItem>
                <LargeNav2024TopItem>
                    {/* MARKET CAP: {formatNumber(Number(marketCap))}{" WAX"} */}
                </LargeNav2024TopItem>  
        <LargeNavSocialIconsDiv>
        {/* Telegram */}
        <a href={config.URLs.telegram} target="none">
              {telegram_svg}
        </a>

      {/* Twitter */}
      <a href={config.URLs.twitter} target="none">
              {twitter_svg}
      </a>

      {/* Discord */}
      <a href={config.URLs.discord} target="none">
              {discord_svg}
        </a>


      {/* Medium */}
      <a href={config.URLs.medium} target="none">
              {medium_svg}
        </a>         
      </LargeNavSocialIconsDiv>                 
            </LargeNavTopLeftWrapper>   
            <LargeNavTopRightWrapper>
            <LargeNavbar2024LoginButton
              onClick={() => {
                if(!isLoggedIn()){
                  logInWithWharfkit(setCurrentUser, setWharfSession);
                }
                else{
                  setShowUserDropDown((prev) => !prev)
                }
              }}
            >
                {isLoggedIn() ? currentUsername : "LOG IN"}
            </LargeNavbar2024LoginButton>
                <ModalOverlay2024 className={!showUserDropDown ? "hidden" : undefined} />
                <UserDropDown open={showUserDropDown}>
                  <UserDrowDownInnerScrollWrapper>
                  <UserDropDownTopSection>
                  <UserDropDownTopLeft>
 
                  </UserDropDownTopLeft>

                  <div style={{//border: "1px solid yellow",
                  width: "10%", textAlign: "right", paddingRight: "5px", fontSize: "22px", fontWeight: "400", color: theme2024.textMain}}><button
                  onClick={() => {
                      setShowUserDropDown(false);
                    }}            
                  >X</button></div>                     
                  </UserDropDownTopSection>   
                  <UserDropDownBalancesCont>
                  <h3>MY BALANCES</h3>
                  {showWaxAndWaxdaoBalance(tokenBalances, balancesAreLoading, userStake, stakeIsLoading)}
                  <a href={`${currentWebsiteURL}/stake`}>
                  <UserDropDownBuyWaxDaoButton>
                    BUY SWAX/LSWAX
                  </UserDropDownBuyWaxDaoButton>
                  </a>
                  </UserDropDownBalancesCont> 
                  <a href={`${currentWebsiteURL}/stake`}> 
                  <UserDropDownLinkButton>
                    <h3>LIQUID STAKING</h3>
                    <p>Manage your SWAX/LSWAX positions</p>
                  </UserDropDownLinkButton> 
                  </a> 
                  <a href={`${currentWebsiteURL}/long-term-rent`}> 
                  <UserDropDownLinkButton>
                    <h3>LONG TERM CPU RENTAL</h3>
                    <p>Rent CPU for 30 days or more</p>
                  </UserDropDownLinkButton> 
                  </a>    
                  <a href={`${currentWebsiteURL}/rent`}> 
                  <UserDropDownLinkButton>
                    <h3>SHORT TERM CPU RENTAL</h3>
                    <p>Rent CPU for less than 1 month</p>
                  </UserDropDownLinkButton> 
                  </a>    
                  <a href={`${currentWebsiteURL}/powerup`}> 
                  <UserDropDownLinkButton>
                    <h3>PowerUp</h3>
                    <p>Get resources for 24 hours</p>
                  </UserDropDownLinkButton> 
                  </a>    
                  <a href={`${currentWebsiteURL}/status`}> 
                  <UserDropDownLinkButton>
                    <h3>Status</h3>
                    <p>View important protocol messages</p>
                  </UserDropDownLinkButton> 
                  </a>                                                         

                  <UserDropDownWalletsSeparator />
                  <UserDropDownWalletsTitleCont>
                  <h3>MY WALLETS</h3><button onClick={() => {
                    logInWithWharfkit(setCurrentUser, setWharfSession)
                  }
                    
                    }>+</button>
                  </UserDropDownWalletsTitleCont>      
                  <span className={sessions?.length > 1 ? "hidden" : undefined}>
                  <UserDropDownLinkButton onClick={() => logOutWharfkit(setCurrentUser, setWharfSession)}>
                    <h3>LOG OUT</h3>
                    <p>End your session with WaxFusion</p>
                  </UserDropDownLinkButton> 
                  </span>
                  {sessions?.length > 1 && 
                  sessions.map((item, index) => (
                    <span key={index} className={item.actor == currentUsername && item.permission == 
                    JSON.parse(localStorage.getItem('wharf--session')).permission && "hidden"}>
                      <WaxAccountButton>
                        <WaxAccountInnerCont>
                          <WaxAccountLeft>
                          <img src={getWalletLogo(item)} /> 
                          </WaxAccountLeft>

                        <WaxAccountMiddle
                          onClick={() => {
                            switchWharfAccount(item.actor, item.permission, item.walletPlugin.id, setCurrentUser, setWharfSession)
                          }}
                        >
                        {item.actor} ({item.permission})
                        </WaxAccountMiddle>
                        
                      <WaxAccountGap />

                        <WaxAccountRight
                          onClick={() => {
                            removeWharfSession(item.actor, item.permission, item.walletPlugin.id, setSessions)
                          }}
                        >
                        <FaTrashAlt
                          style={{display:"inline-block", color: waxfusionTheme.darkBlue}}
                        />
                        </WaxAccountRight>
                        </WaxAccountInnerCont>                
                      </WaxAccountButton>
                      </span>
                  ))}                            
                  </UserDrowDownInnerScrollWrapper>
                </UserDropDown>            
            </LargeNavTopRightWrapper>                   
        </LargeNav2024TopWrapper>
        <LargeNav2024BottomWrapper>
            <a href={currentWebsiteURL}>
            {/* <img src={header_logo} /> */}
            <h2>WaxFusion</h2>
            </a>
            <LargeNav2024LinksWrapper>
                <a href={`${currentWebsiteURL}/stake`}>
                <LargeNav2024LinkButton>
                    {water_svg} LIQUID STAKING
                </LargeNav2024LinkButton>
                </a>

                
                <LargeNav2024LinkButton onBlur={(e) => handleNavBlur(e, setSelectedTab)} onClick={() => {
                  selectedTab == "CPU Rental" ? setSelectedTab("") : setSelectedTab("CPU Rental");
                }}>
                    {cpu_svg} CPU RENTAL
                    <LargeNavLinkDropDown open={selectedTab == "CPU Rental"}>
                    <LargeNavDropDownInnerWrapper>
                      <a href={`${currentWebsiteURL}/rent`}>
                      <LargeNavLinkDropDownRow>
                        SHORT TERM RENTAL
                      </LargeNavLinkDropDownRow>
                      </a> 
                      <a href={`${currentWebsiteURL}/long-term-rent`}>
                      <LargeNavLinkDropDownRow>
                      LONG TERM RENTAL
                      </LargeNavLinkDropDownRow>
                      </a>      
                      <a href={`${currentWebsiteURL}/powerup`}>
                      <LargeNavLinkDropDownRow>
                      POWERUP
                      </LargeNavLinkDropDownRow>
                      </a>                                
                      </LargeNavDropDownInnerWrapper>             
                    </LargeNavLinkDropDown>                    
                </LargeNav2024LinkButton>  
                

                <a href={config.URLs.docs} target="none">
                <LargeNav2024LinkButton>
                    {docs_svg} DOCS
                </LargeNav2024LinkButton>  
                </a>    

                <a href={`${currentWebsiteURL}/status`}>
                <LargeNav2024LinkButton>
                    {status_svg} STATUS
                </LargeNav2024LinkButton>  
                </a>                                                                                                             
            </LargeNav2024LinksWrapper>

        </LargeNav2024BottomWrapper>
    </LargeNavWrapper2024>

    {/* Small Screen Navbar */}

    <SmallNavWrapper2024>
    <ModalOverlay2024 className={!showSmallScreenDropDown ? "hidden" : undefined} />
      <LargeNav2024TopWrapper>
        <SmallNavTopLeftWrapper>
        <a href={currentWebsiteURL}>
        {/* <img src={white_logo} /> */}
        <h2>WaxFusion</h2>
        </a>
        </SmallNavTopLeftWrapper>
        <SmallNavTopRightWrapper open={showSmallScreenDropDown}>
          <button onClick={() => showSmallScreenDropDown ? setShowSmallScreenDropDown(false) : setShowSmallScreenDropDown(true)}>
          {!showSmallScreenDropDown && burger_menu_icon}
          </button>
        </SmallNavTopRightWrapper>
      </LargeNav2024TopWrapper>

    </SmallNavWrapper2024>
    <SmallNavDropDown open={showSmallScreenDropDown}>

      <CloseDivXIconWrapper>
      <button
      onClick={() => {
          setShowSmallScreenDropDown(false);
        }}            
      >{x_close_svg}</button>
      </CloseDivXIconWrapper>
      
      <UserDrowDownInnerScrollWrapper>
      {!isLoggedIn() && (
        <span>

          <SmallNavLoginButtonWrapper>
          <SmallNavbar2024LoginButton
            onClick={() => {
                logInWithWharfkit(setCurrentUser, setWharfSession);
            }}
          >
          LOG IN
          </SmallNavbar2024LoginButton>
          </SmallNavLoginButtonWrapper>          
        </span>
      )}

      {isLoggedIn() && (
        <span>
  
                  <UserDropDownBalancesCont>
                  <h3>MY BALANCES</h3>
                  {showWaxAndWaxdaoBalance(tokenBalances, balancesAreLoading, userStake, stakeIsLoading)}
                  <a href={`${currentWebsiteURL}/stake`}>
                  <UserDropDownBuyWaxDaoButton>
                    BUY SWAX/LSWAX
                  </UserDropDownBuyWaxDaoButton>
                  </a>
                  </UserDropDownBalancesCont> 
                  </span>
        )}              

                  <a href={`${currentWebsiteURL}/stake`}> 
                  <UserDropDownLinkButton>
                    <h3>LIQUID STAKING</h3>
                    <p>Manage your SWAX/LSWAX positions</p>
                  </UserDropDownLinkButton> 
                  </a> 
                  <a href={`${currentWebsiteURL}/long-term-rent`}> 
                  <UserDropDownLinkButton>
                    <h3>LONG TERM CPU RENTAL</h3>
                    <p>Rent CPU for 30 days or more</p>
                  </UserDropDownLinkButton> 
                  </a>    
                  <a href={`${currentWebsiteURL}/rent`}> 
                  <UserDropDownLinkButton>
                    <h3>SHORT TERM CPU RENTAL</h3>
                    <p>Rent CPU for less than 1 month</p>
                  </UserDropDownLinkButton> 
                  </a>    
                  <a href={`${currentWebsiteURL}/powerup`}> 
                  <UserDropDownLinkButton>
                    <h3>PowerUp</h3>
                    <p>Get resources for 24 hours</p>
                  </UserDropDownLinkButton> 
                  </a>  
                  <a href={`${currentWebsiteURL}/status`}> 
                  <UserDropDownLinkButton>
                    <h3>Status</h3>
                    <p>View important protocol messages</p>
                  </UserDropDownLinkButton> 
                  </a>                    

                  

                  {isLoggedIn() && (
                    <span>
                  <UserDropDownWalletsSeparator />
                  <UserDropDownWalletsTitleCont>
                  <h3>{sessions?.length == 1 ? "ADD WALLET" : "MY WALLETS"}</h3><button onClick={() => {
                    logInWithWharfkit(setCurrentUser, setWharfSession)
                  }
                  }>+</button>
                  {sessions?.length > 1 && <button onClick={() => setShowWallets((prev) => !prev)}>{showWallets ? x_close_svg : down_arrow_svg}</button>}
                  </UserDropDownWalletsTitleCont>      
                  <span className={sessions?.length > 1 ? "hidden" : undefined}>
                  <UserDropDownLinkButton onClick={() => logOutWharfkit(setCurrentUser, setWharfSession)}>
                    <h3>LOG OUT</h3>
                    <p>End your session with WaxFusion</p>
                  </UserDropDownLinkButton> 
                  </span>

                  <SmallScreenWalletsWrapper open={showWallets}>
                  {sessions?.length > 1 && 
                  sessions.map((item, index) => (
                    <span key={index} className={item.actor == currentUsername && item.permission == 
                    JSON.parse(localStorage.getItem('wharf--session')).permission ? "hidden" : undefined}>
                      <WaxAccountButton>
                        <WaxAccountInnerCont>
                          <WaxAccountLeft>
                          <img src={getWalletLogo(item)} /> 
                          </WaxAccountLeft>

                        <WaxAccountMiddle
                          onClick={() => {
                            switchWharfAccount(item.actor, item.permission, item.walletPlugin.id, setCurrentUser, setWharfSession)
                          }}
                        >
                        {item.actor} ({item.permission})
                        </WaxAccountMiddle>
                        
                      <WaxAccountGap />

                        <WaxAccountRight
                          onClick={() => {
                            removeWharfSession(item.actor, item.permission, item.walletPlugin.id, setSessions)
                          }}
                        >
                        <FaTrashAlt
                          style={{display:"inline-block", color: waxfusionTheme.darkBlue}}
                        />
                        </WaxAccountRight>
                        </WaxAccountInnerCont>                
                      </WaxAccountButton>
                      </span>
                  ))}
                  </SmallScreenWalletsWrapper>
                    </span>
                  )}            



      

                                      
        <br/>
        <SmallNavBottomWrapper>
        <SmallNavSocialIconsDiv>
        {/* Telegram */}
        <a href={config.URLs.telegram} target="none">
          {telegram_svg}
        </a>

      {/* Twitter */}
      <a href={config.URLs.twitter} target="none">
        {twitter_svg}
      </a>

      {/* Discord */}
      <a href={config.URLs.discord} target="none">
        {discord_svg}
      </a>


      {/* Medium */}
      <a href={config.URLs.medium} target="none">
              {medium_svg}
        </a>         
      </SmallNavSocialIconsDiv>          
        <LargeNav2024TopItem>
        LSWAX BACKING: {Number(getSWaxPerLSWax(fusionState, stateIsLoading)).toFixed(4)}{" WAX"}
        </LargeNav2024TopItem>
        </SmallNavBottomWrapper>
                  </UserDrowDownInnerScrollWrapper>        
    </SmallNavDropDown>
    </div>
  );
};

export default Navbar2024;
