import { useState } from "react";
import { DAPP_CONTRACT, TESTNET, mainnetApiList, testnetApiList } from "../../data/config";
import axios from 'axios';

export const useGetFusionState = () => {
    
    const [fusionState, setFusionState] = useState([]);
    const [stateIsLoading, setStateIsLoading] = useState(true);

    let apiList = TESTNET ? testnetApiList : mainnetApiList;

    const getFusionState = async () => {
        setStateIsLoading(true);

        for (const api of apiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "global",
                    scope: DAPP_CONTRACT,
                    code: DAPP_CONTRACT,
                    limit: 1,
                    json: true,
                });
                
                if (res.data.rows) {
                    setFusionState(res.data.rows[0]);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }
        setStateIsLoading(false);
    }

    return [fusionState, getFusionState, stateIsLoading]
}
