import styled from "styled-components";
import { theme2024, waxfusionTheme } from "./Styles2024";

export const StatsWrapper = styled.div`
    width: 100%;
    height: 80px;
    background-color: ${waxfusionTheme.darkBlue};
    color: white;
    padding-top: 10px;

    h2{
        font-size: 28px;
        height: 28px;
        font-weight: 600;
        letter-spacing: 0.9px;
        width: 100%;
        text-align: center;
        color: ${theme2024.offWhite};
    }

    h3{
        margin-top: 10px;
        font-size: 12px;
        letter-spacing: 0.7px;
        width: 100%;
        text-align: center;
        color: ${theme2024.offWhite};
    }
`

export const CardsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    height: auto;
    //border: 1px solid green;
    min-height: 100px;
    gap: 25px;
    padding: 25px;
    background: rgb(156,207,225);
    background: -moz-radial-gradient(circle, rgba(156,207,225,1) 0%, rgba(116,178,209,1) 58%);
    background: -webkit-radial-gradient(circle, rgba(156,207,225,1) 0%, rgba(116,178,209,1) 58%);
    background: radial-gradient(circle, rgba(156,207,225,1) 0%, rgba(116,178,209,1) 58%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9ccfe1",endColorstr="#74b2d1",GradientType=1);

    h2{
        width: 100%;
        font-weight: 600;
        font-size: 24px;
        text-align: center;
    }    
`

export const WhyCard = styled.div`
    width: 400px;
    height: 220px;
    background-color: ${waxfusionTheme.background};
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;

    h3{
        position: absolute;
        top: 90px;
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
    }

    p{
        position: absolute;
        top: 120px;
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: ${theme2024.darkGrey};
        padding: 0px 10px 0px 10px;

        @media(max-width: 450px){
            font-size: 10px;
        }
    }

    button{
        position: absolute;
        top: 150px;
        width: 90%;
        left: 5%;
        color: white;
        height: 60px;
        background-color: ${waxfusionTheme.darkBlue};
        border-radius: 7px;
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);

    }    

    svg{
        fill: ${theme2024.darkGrey};
        width: 50px;
        height: 50px;
    }
`

export const FoldersRowCentered = styled.div`
    width: 100%;
    height: 30px;
    font-size: 14px;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
        font-size: 12px;
    }

    @media (max-width: 450px) {
        font-size: 10px;
    }

    @media (max-width: 375px) {
        font-size: 8px;
    }
`

export const ShowPriceContainer = styled.div`
    width: 500px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    padding: 15px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);

    span{
        font-size: 12px;
        color: ${theme2024.darkGrey};
    }

    @media (max-width: 560px) {
      width: 95%;
  }    
`

export const StakeContainer = styled.div`
    width: 500px;
    background-color: ${props => props.bg ? props.bg : "white"};
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    padding: ${props => props.padding ? props.padding : "15px"};
    text-align: center;
    border-radius: 15px;
    padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : "25px"};
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);

    span{
        font-size: 12px;
        color: ${theme2024.darkGrey};
        
    }

    h2{
        font-size: 24px;
        color: ${theme2024.textMain};
        font-weight: 600;
    }

    button{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25px;
        color: white;
        height: 60px;
        background-color: ${waxfusionTheme.darkBlue};
        border-radius: 7px;
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);

    }     

    @media (max-width: 560px) {
      width: 95%;
  }    
`

export const LogoPlusHeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

    h2{
        font-size: 24px;
        color: ${waxfusionTheme.darkBlue};
        font-weight: 600;
    }  

    h3{
        font-size: 18px;
        color: ${waxfusionTheme.darkBlue};
        font-weight: 500;        
    }

  svg{
    position: absolute;
    width: 35px;
    height: 35px;
    fill: ${waxfusionTheme.darkBlue};
    top: 0px;
    left: 20%;
  }  
`

export const FusionInputWrapper = styled.div`
    width: 100%;

    input, select{
        font-size: 18px;
        color: ${waxfusionTheme.darkBlue};
        background-color: ${theme2024.offWhite};
        border: 1px solid ${waxfusionTheme.offWhiteDarker};
        border-radius: 7px;
        padding: 5px;
        padding-left: 10px;
        width: ${props => props.wide ? '90%' : 'auto'};

        :focus{
            outline: none;
            border: 1px solid ${theme2024.darkGrey};
        }
    }

    textarea{
      background-color: transparent;
      border: 1px solid ${theme2024.secondaryLight};
      border-radius: 7px;
      padding: 5px;
      padding-left: 10px;
      width: ${props => props.wide ? '90%' : 'auto'};
      max-width: ${props => props.wide ? '90%' : '100%'};
      resize:  ${props => props.allowResize ? 'auto' : 'none'};

      &::-webkit-scrollbar-track {
        border-radius: 15px; 
        background-color: transparent; 
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: ${theme2024.primary};
    }

    &::-webkit-scrollbar {
        width: 4px;
    }    
    
    //Firefox
    scrollbar-color: ${theme2024.primary} transparent;
    scrollbar-width: thin;       

      :focus{
          outline: 2px solid ${theme2024.primary};
      }
  }    

    h3{
        width: 100%;
        padding-top: 5px;
        font-size: 12px;
        color: ${theme2024.textSecondary};
    } 
    
    h4{
        width: 100%;
        padding-top: 5px;
        font-size: 12px;
        color: ${theme2024.darkGrey};
    }     
`

export const QuickSelectWrapper = styled.div`
    width: 100%;
    display: flex;
    display: flex;
    justify-content: space-around;
    padding-left: 5px;
    padding-right: 5px;

    button{
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        color: ${waxfusionTheme.darkBlue};
        background-color: ${theme2024.offWhite};
        border: 1px solid ${waxfusionTheme.offWhiteDarker};
        border-radius: 5px;
        padding: 5px;
        width: 70px;
        max-width: 23%;
        height: 25px;
        margin-top: 7px;

    }

`

export const MessageWrapper = styled.div`
    width: 90%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 12px;
    background-color: ${theme2024.offWhite};
    color: ${theme2024.darkGrey};
    padding: 10px;
    height: ${props => props.height && props.height};
    margin-top: ${props => props.top ? props.top : "25px"};
    border-radius: 10px;
    border: 1px solid ${waxfusionTheme.offWhiteDarker};

    a{
        color: ${waxfusionTheme.darkBlue};
        font-weight: 600;
    }
`

export const RentalWrapper = styled.div`
    position: relative;
    height: ${props => !props.show ? "100px" : props.rentalTab == "Extend" ? "500px" : "495px"};
    width: 90%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 14px;
    //font-weight: 500;
    background-color: white;
    color: ${theme2024.darkGrey};
    padding: 10px;
    margin-top: 25px;
    border-radius: 10px;
    border: 1px solid ${waxfusionTheme.offWhiteDarker};
    transition: height 0.3s;
`

export const RentalDetailsWrapper = styled.div`

    background-color: white;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);

    position: absolute;
    top: 99px;
    height: ${props => !props.show ? "0px" : props.rentalTab == "Extend" ? "410px" : "405px"};
    width: 100%;
    left: 0px;
    visibility: ${props => !props.show && "hidden"};
    overflow: hidden;
    transition: height 0.3s, visibility 0.3s;
    
`

export const BigBlueButton = styled.button`

    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    color: white;
    height: 60px;
    background-color: ${waxfusionTheme.darkBlue};
    border-radius: 7px;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);

`

export const ShowDetailsButton = styled.button`
    position: absolute;
    top: 65px;
    height: 25px;
    width: 90%;
    left: 5%;
    border: 1px solid gray;
    border-radius: 7px;
    transition: color 0.3s, border 0.3s, letter-spacing 0.15s, background-color 0.15s;

    :hover{
        color: ${waxfusionTheme.darkBlue};
        border: 1px solid ${waxfusionTheme.darkBlue};
        letter-spacing: 1px;
        background-color: rgba(0, 0, 0, 0.03);
    }
`

export const LiquifyToggleCont = styled.div`
    width: 80px;
    font-size: 12px;
    font-weight: 500;
    color: ${waxfusionTheme.darkBlue};
`


export const SpaceBetweenDiv = styled.div`
    display: flex;
    position: ${props => props.top && 'absolute'};
    top: ${props => props.top && props.top};
    padding-left: ${props => props.top && '10px'};
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    //margin-top: 20px;
    margin-bottom: 5px;
    justify-content: space-between;
    color: ${waxfusionTheme.darkBlue};

    @media(max-width: 535px){
        flex-wrap: ${props => props.wrap && 'wrap'};
    }    
`

export const ClickableP = styled.p`
    :hover{
        cursor: pointer;
    }
`

export const UseWaxFusionCont = styled.div`
    width: 200px;
    height: 90px;
    border-radius: 7px;
    background-color: ${theme2024.offWhite};
    border: ${props => props.selected ? `1px solid ${waxfusionTheme.darkBlue}` : `1px solid ${waxfusionTheme.offWhiteDarker}`}; 
    position: relative;

    h3{
        position: absolute;
        font-size: 12px;
        color: ${theme2024.darkGrey};
        top: 10px;
        width: 100%;
        padding-left: 10px;
        left: 0px;
        text-align: left;
        font-weight: 600;
    }

    :hover{
        cursor: pointer;
    }

    @media(max-width: 535px){
        width: 100%;
        margin-bottom: 15px;
    }
`

export const FusionSlider = styled.div`
  width: 40px;
  height: 20px;
  background-color: ${(props) => (props.toggled ? "#7fcfec" : waxfusionTheme.gray)};
  border-radius: 20px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.5s ease;
`;

export const FusionSliderCircle = styled.div`
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.5s ease;
  left: ${(props) => (props.toggled ? 'calc(100% - 10px)' : '10px')};
`;

export const NotFoundWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 80px;

    h2{
        width: 100%;
        font-size: 124px;
        font-weight: 700;
        letter-spacing: 1.5px;
        color: ${waxfusionTheme.darkBlue};
        text-align: center;
    }

    h3{
        width: 100%;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 1px;
        color: white;
        text-align: center;  
        margin-top: 20px;      
    }

    p{
        width: 300px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.4px;
        color: ${waxfusionTheme.darkBlue};
        text-align: center;  
        margin-top: 10px;          
    }

    a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        max-width: 90%;        
        color: white;
        height: 40px;
        background-color: ${waxfusionTheme.darkBlue};
        border-radius: 10px;
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);     
        margin-left: auto;
        margin-right: auto;  
        margin-top: 10px;     
    }
`

export const LargerSlider = styled.div`
  width: 250px;
  height: 44px;
  //background-color: ${(props) => (props.toggled ? "#7fcfec" : waxfusionTheme.gray)};
  background-color: ${waxfusionTheme.lighterBlue};
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.5s ease;
`;

export const LeftSliderLabel = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 120px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${waxfusionTheme.offWhiteDarker};
    z-index: 10;
`

export const RightSliderLabel = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    width: 120px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${waxfusionTheme.offWhiteDarker};
    z-index: 10;
`

export const LargerToggleCont = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: ${waxfusionTheme.darkBlue};
`;

export const LargerSliderCircle = styled.div`
  width: 140px;
  height: 42px;
  background-color: ${waxfusionTheme.darkBlue};
  color: white;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.5s ease;
  left: ${(props) => (props.toggled ? 'calc(100% - 72px)' : '72px')};
  
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
`;

export function ToggleSwitch({ toggled, onToggle }) {
    return (
      <LargerToggleCont onClick={onToggle}>
        <LargerSlider toggled={toggled}>
          <LargerSliderCircle toggled={toggled}>
          {toggled ? 'LSWAX' : 'SWAX'}
          </LargerSliderCircle>
          <LeftSliderLabel>
            SWAX
          </LeftSliderLabel>
          <RightSliderLabel>
            LSWAX
          </RightSliderLabel>          
        </LargerSlider>        
      </LargerToggleCont>
    );
  }
  
  export default ToggleSwitch;