import React from "react";
import {
  CheckboxContainer,
  FolderTab2024,
  FoldersContainer2024,
  HeaderCont2024,
  InfoIsLoadingBlur,
  PageBody2024,
  PageWrapper2024,
  theme2024,
} from "../Styles2024";
import ToggleSwitch, {
  ClickableP,
  FoldersRowCentered,
  FusionInputWrapper,
  FusionSlider,
  FusionSliderCircle,
  LiquifyToggleCont,
  LogoPlusHeaderWrapper,
  MessageWrapper,
  SpaceBetweenDiv,
  StakeContainer,
  UseWaxFusionCont,
} from "../FusionStyles";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { stake_svg } from "../data/svgs";
import { isLoggedIn } from "../data/functions/wallet_functions";
import {
  claimRewards,
  clearExpiredTx,
  getLsWaxPerSWax,
  getSWaxPerLSWax,
  instaRedeem,
  liquifyTx,
  redeemTx,
  reqredeem,
  stakeWax,
  unliquify,
} from "../data/functions/fusion_functions";
import { useStateContext } from "../contexts/ContextProvider";
import { logInWithWharfkit } from "../data/wharfkit";
import { DEFAULT_SLIPPAGE_TOLERANCE, currentUsername } from "../data/config";
import { useGetMyRedemptions } from "../components/CustomHooks/useGetMyRedemptions";
import { useGetClaimableRewards } from "../components/CustomHooks/useGetClaimableRewards";
import { tokens_svg } from "../data/constants";
import { showRentalPool } from "./Rent";
import { useGetCurrentEpochs } from "../components/CustomHooks/useGetCurrentEpochs";
import { useGetFusionState } from "../components/CustomHooks/useGetFusionState";
import TransactionModal from "../components/TransactionModal";
import { useGetRewardPool } from "../components/CustomHooks/useGetRewardPool";
import ama_banner from "../data/ama_banner.png";
import { useGetAlcorSwapRate } from "../components/CustomHooks/useGetAlcorSwapRate";

const calculateApr = (r) => {
  const todaysPool = (r.rewardRate * 86400) / 1e16;
  const yearlyPool = todaysPool * 365;
  const totalSupply = r?.totalSupply / 1e8;
  return `${Number((yearlyPool / totalSupply) * 100).toFixed(2)}%`;
};

export const addThousandsSeparator = (x) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

const showRedemptionPools = (epochs) => {
  const now = new Date();
  const currentTime = Math.floor(now.getTime() / 1000);
  let redemption_pools = [];

  for (const ep of epochs) {
    const daysRemaining = (
      (Number(ep.redemption_period_start_time) - currentTime) /
      86400
    ).toFixed(2);
    const waxAvailable = Number(
      Number(ep.wax_bucket.split(" ")[0]) -
        Number(ep.wax_to_refund.split(" ")[0])
    );

    if (waxAvailable > 0) {
      redemption_pools.push({
        days_remaining: parseFloat(daysRemaining),
        wax_available: addThousandsSeparator(waxAvailable.toFixed(2)),
      });
    }
  }

  if (redemption_pools.length === 0) {
    return <span>Couldn't locate redemption pools.</span>;
  }

  const poolDescriptions = redemption_pools.map((pool, index) => (
    <span key={index}>
      <b>{pool.wax_available} WAX</b> available in{" "}
      <b>{pool.days_remaining} days</b>
    </span>
  ));

  if (poolDescriptions.length > 1) {
    const lastElement = poolDescriptions.pop();
    return (
      <span>
        There is {poolDescriptions.reduce((prev, curr) => [prev, ", ", curr])},
        and {lastElement}. Your transaction will automatically request as much
        as possible from each pool, starting from earliest to latest.
      </span>
    );
  } else {
    return (
      <span>
        There is {poolDescriptions[0]}. Your transaction will automatically
        request as much as possible from each pool, starting from earliest to
        latest.
      </span>
    );
  }
};

export function isValidNumber(input) {
  const number = parseFloat(input);
  return !isNaN(number) && isFinite(number) && /^\d*\.?\d*$/.test(input);
}

const claimInfo = (epoch_id) => {
  const now = new Date();
  const currentTime = Math.floor(now.getTime() / 1000);

  const epoch_start = Number(epoch_id);
  const claim_start = epoch_start + 60 * 60 * 24 * 14;
  const claim_end = epoch_start + 60 * 60 * 24 * 16;

  let claimObject = {
    claimable: false,
    expired: false,
    hoursLeftBeforeClaim: 0,
    hoursBeforeExpires: 0,
  };

  if (claim_start > currentTime) {
    //there's time left before claiming
    claimObject.hoursLeftBeforeClaim = Number(
      (claim_start - currentTime) / (60 * 60)
    ).toFixed(1);

    return claimObject;
  }

  if (currentTime > claim_end) {
    //its too late to claim
    claimObject.expired = true;

    return claimObject;
  }

  //if we reached here, its claimable
  claimObject.claimable = true;
  claimObject.hoursBeforeExpires = Number(
    (claim_end - currentTime) / (60 * 60)
  ).toFixed(1);

  return claimObject;
};

const showExchangeRate = (liquify, s, loading) => {
  if (liquify) {
    return `1 WAX = ${getLsWaxPerSWax(s, loading)?.toFixed(4)} LSWAX`;
  }
  return "1 WAX = 1 SWAX";
};

const showExchangeRateForLiquifying = (s, loading) => {
  return `1 SWAX = ${getLsWaxPerSWax(s, loading)?.toFixed(4)} LSWAX`;
};

const showExchangeRateForClaiming = (claimAs, s, loading) => {
  if (claimAs == "LSWAX") {
    return `1 WAX = ${getLsWaxPerSWax(s, loading)?.toFixed(4)} LSWAX`;
  } else if (claimAs == "SWAX") {
    return "1 WAX = 1 SWAX";
  } else if (claimAs == "WAX") {
    return "1:1";
  } else {
    return "Unknown";
  }
};

export const showOutput = (amount, liquify, s, loading) => {
  let outputObject = {
    expected: Number(0),
    minimum: Number(0),
  };

  if (loading || !amount || amount == "") return outputObject;

  if (!liquify) {
    outputObject.expected = Number(amount);
    outputObject.minimum = Number(amount);
    return outputObject;
  }

  outputObject.expected = Number(getLsWaxPerSWax(s, loading) * amount);
  outputObject.minimum = Number(
    getLsWaxPerSWax(s, loading) * (1.0 - DEFAULT_SLIPPAGE_TOLERANCE) * amount
  );

  return outputObject;
};

export const showUnliquifyOutput = (amount, s, loading) => {
  let outputObject = {
    expected: Number(0),
    minimum: Number(0),
  };

  if (loading || !amount || amount == "") return outputObject;

  outputObject.expected = Number(getSWaxPerLSWax(s, loading) * amount);
  outputObject.minimum = Number(
    getSWaxPerLSWax(s, loading) * (1.0 - DEFAULT_SLIPPAGE_TOLERANCE) * amount
  );

  return outputObject;
};

export const showOutputForClaiming = (amount, claimAs, s, loading) => {
  let outputObject = {
    expected: Number(0),
    minimum: Number(0),
  };

  if (loading || !amount || amount == 0) return outputObject;

  if (claimAs != "LSWAX") {
    outputObject.expected = Number(amount);
    outputObject.minimum = Number(amount);
    return outputObject;
  }

  outputObject.expected = Number(getLsWaxPerSWax(s, loading) * amount);
  outputObject.minimum = Number(
    getLsWaxPerSWax(s, loading) * (1.0 - DEFAULT_SLIPPAGE_TOLERANCE) * amount
  );

  return outputObject;
};

export const showWaxBalance = (tokens, loading) => {
  if (loading) return <span>0</span>;

  let waxBalance = 0;
  let realBalance = 0;

  tokens.forEach((t) => {
    if (t.currency === "WAX" && t.contract === "eosio.token") {
      waxBalance = Math.floor(Number(t.amount) * 100) / 100;
      realBalance = Number(t.amount);
    }
  });

  return [<span>{waxBalance}</span>, realBalance];
};

const showLSWaxBalance = (tokens, loading) => {
  if (loading) return <span>0</span>;

  let waxBalance = 0;
  let realBalance = 0;

  tokens.forEach((t) => {
    if (t.currency === "LSWAX" && t.contract === "token.fusion") {
      waxBalance = Math.floor(Number(t.amount) * 100) / 100;
      realBalance = Number(t.amount);
    }
  });

  return [<span>{waxBalance}</span>, realBalance];
};

export const showSwaxBalance = (userStake, stakeIsLoading) => {
  if (stakeIsLoading) return <span>0</span>;

  let swaxBalance = 0;

  if (userStake?.swax_balance) {
    swaxBalance =
      Math.floor(Number(userStake?.swax_balance.split(" ")[0]) * 100) / 100;
    //swaxBalance = Number(userStake?.swax_balance.split(" ")[0]).toFixed(2);
  }

  return <span>{swaxBalance}</span>;
};

const tabParamIsValid = (tab) => {
  const validParams = ["stake", "withdrawals", "liquify", "rewards"];

  if (validParams.includes(tab)) {
    return true;
  }

  return false;
};

const showRedeemMessage = () => {
  return "You don't have any pending withdrawal requests";
};

const showMinReceived = (
  swapRateIsLoading,
  inputAmount,
  outputAmount,
  minReceived
) => {
  if (inputAmount.length == 0 && outputAmount.length == 0) return;

  if (swapRateIsLoading) return <InfoIsLoadingBlur />;

  const minRounded = Number(minReceived).toFixed(3);

  return `${minRounded} WAX`;
};

const showSwapRate = (
  swapRateIsLoading,
  inputAmount,
  outputAmount,
  tokenName
) => {
  if (inputAmount.length == 0 && outputAmount.length == 0) return;

  if (swapRateIsLoading) return <InfoIsLoadingBlur />;

  console.log(`input amount: ${inputAmount}`)

  return (
    <span>
      1 {tokenName} = {Number(outputAmount / inputAmount).toFixed(4)} WAX
    </span>
  );
};

const showPriceImpact = (
  swapRateIsLoading,
  inputAmount,
  outputAmount,
  priceImpact
) => {
  if (inputAmount.length == 0 && outputAmount.length == 0) return;

  if (swapRateIsLoading) return <InfoIsLoadingBlur />;

  const impact = Number(priceImpact).toFixed(1);
  let color;

  if (impact <= 3) {
    color = theme2024.success;
  } else if (impact > 3 && impact <= 5) {
    color = theme2024.caution;
  } else if (impact > 5) {
    color = theme2024.danger;
  }

  return <span style={{ color: color }}>{priceImpact}%</span>;
};

const Stake = () => {
  const {
    wharfSession,
    setWharfSession,
    setCurrentUser,
    fusionState,
    stateIsLoading,
    userStake,
    stakeIsLoading,
    refresh,
    setRefresh,
    tokenBalances,
    balancesAreLoading,
    showTxModal,
    setShowTxModal,
    txModalText,
    setTxModalText,
    txIsLoading,
    setTxIsLoading,
  } = useStateContext();

  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get("tab");

  const [currentTab, setCurrentTab] = useState("");
  const [withdrawTab, setWithdrawTab] = useState("request");
  const [liquifyTab, setLiquifyTab] = useState("liquify");
  const [amountToStake, setAmountToStake] = useState("");
  const [amountToRequest, setAmountToRequest] = useState("");
  const [instantRedeemAmount, setInstantRedeemAmount] = useState("");
  const [amountToLiquify, setAmountToLiquify] = useState("");
  const [amountToUnliquify, setAmountToUnliquify] = useState("");
  const [withdrawFrom, setWithdrawFrom] = useState("waxfusion");
  const [liquify, setLiquify] = useState(false);
  const [claimAs, setClaimAs] = useState("WAX");
  const [tokenToRequest, setTokenToRequest] = useState("SWAX");
  const [acceptReplacingPrev, setAcceptReplacingPrev] = useState(false);
  const [refreshRedemptionPool, setRefreshRedemptionPool] = useState(false);

  //custom hooks
  const [requests, getRequests, requestsAreLoading] = useGetMyRedemptions();
  const [claimableRewards, getClaimableRewards, rewardsAreLoading] =
    useGetClaimableRewards();
  const [epochs, getEpochs, epochsAreLoading] = useGetCurrentEpochs();
  const [fusionConfig, getFusionConfig, configIsLoading] = useGetFusionState();
  const [r, getRewardPool, rIsLoading] = useGetRewardPool();

  // Alcor
  const [swapRate, getSwapRate, swapRateIsLoading] = useGetAlcorSwapRate();
  const [swapMethod, setSwapMethod] = useState("EXACT_INPUT");
  const [outputAmount, setOutputAmount] = useState("");
  const [status, setStatus] = useState(0);
  const [minReceived, setMinReceived] = useState("");
  const [memo, setMemo] = useState("");
  const [priceImpact, setPriceImpact] = useState("");

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (tabParam && tabParamIsValid(tabParam)) {
        setCurrentTab(tabParam);
      } else {
        setCurrentTab("stake");
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (currentTab == "rewards" && isLoggedIn()) {
        getClaimableRewards(currentUsername);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [currentTab, stakeIsLoading, userStake, refresh]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && withdrawTab == "claim") {
      getRequests();
    }

    return () => {
      isMounted = false;
    };
  }, [withdrawTab]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getRewardPool();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (
        !fusionConfig.admin_wallets ||
        fusionConfig?.admin_wallets.length == 0
      ) {
        getFusionConfig();
      }

      if (
        (currentTab == "withdrawals" &&
          !configIsLoading &&
          fusionConfig &&
          epochs.length == 0) ||
        refreshRedemptionPool
      ) {
        let epoch1 =
          fusionState?.last_epoch_start_time +
          fusionConfig?.seconds_between_epochs;
        let epoch2 = fusionState?.last_epoch_start_time;
        let epoch3 =
          fusionState?.last_epoch_start_time -
          fusionConfig?.seconds_between_epochs;
        getEpochs([epoch1, epoch2, epoch3]);
        setRefreshRedemptionPool(false);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    currentTab,
    fusionState,
    stateIsLoading,
    fusionConfig,
    configIsLoading,
    refreshRedemptionPool,
  ]);

  useEffect(() => {
    getSwapRate(
      tokenToRequest == "LSWAX" ? amountToRequest : showOutput(
        amountToRequest,
        true,
        fusionState,
        stateIsLoading
      )?.minimum?.toFixed(4),
      0,
      {
        contract: "token.fusion",
        currency: "LSWAX",
        decimals: 8,
      },
      { contract: "eosio.token", currency: "WAX", decimals: 8 },
      swapMethod,
      setAmountToRequest,
      setOutputAmount,
      setMinReceived,
      setMemo,
      setPriceImpact,
      status,
      setStatus
    );
  }, [amountToRequest]);

  return (
    <div>
      {showTxModal && (
        <TransactionModal
          setShowTxModal={setShowTxModal}
          txModalText={txModalText}
          txIsLoading={txIsLoading}
        />
      )}
      <PageWrapper2024>
        <PageBody2024>
          <HeaderCont2024>
            <div>
              <h2>LIQUID STAKING</h2>
            </div>

            <div>
              <h3>Earn yield staking WAX while staying liquid</h3>
            </div>
          </HeaderCont2024>

          <div className="w-100 flex justify-center items-center mb-4">
            <a
              href="https://youtube.com/live/CsIP90riNQU"
              className="flex justify-center"
              target="none"
            >
              <img
                src={ama_banner}
                style={{
                  width: "500px",
                  height: "auto",
                  borderRadius: "15px",
                  maxWidth: "90%",
                }}
              />
            </a>
          </div>

          <FoldersContainer2024>
            <FoldersRowCentered>
              <FolderTab2024
                selected={currentTab == "stake"}
                disabled={currentTab == "stake"}
                onClick={() => {
                  setCurrentTab("stake");
                }}
              >
                Stake
              </FolderTab2024>

              <FolderTab2024
                selected={currentTab == "withdrawals"}
                disabled={currentTab == "withdrawals"}
                onClick={() => {
                  setCurrentTab("withdrawals");
                }}
              >
                Withdrawals
              </FolderTab2024>

              <FolderTab2024
                selected={currentTab == "rewards"}
                disabled={currentTab == "rewards"}
                onClick={() => {
                  setCurrentTab("rewards");
                }}
              >
                Rewards
              </FolderTab2024>

              <FolderTab2024
                selected={currentTab == "liquify"}
                disabled={currentTab == "liquify"}
                onClick={() => {
                  setCurrentTab("liquify");
                }}
              >
                Liquify
              </FolderTab2024>
            </FoldersRowCentered>
          </FoldersContainer2024>

          {currentTab == "stake" && (
            <>
              <StakeContainer>
                <SpaceBetweenDiv>
                  <p>Current APR</p>
                  <p>
                    {!rIsLoading && r?.totalSupply && <b>{calculateApr(r)}</b>}
                  </p>
                </SpaceBetweenDiv>
                <br />
                <SpaceBetweenDiv>
                  <p>
                    Note that the APR is calculated in real time. It is based on
                    the total amount staked in the protocol, and the current
                    reward rate. This number can change very quickly, so please
                    do not make long term decisions based on this APR.
                  </p>
                </SpaceBetweenDiv>
              </StakeContainer>
              <StakeContainer>
                <LogoPlusHeaderWrapper>
                  {stake_svg}
                  <h2>Stake</h2>
                </LogoPlusHeaderWrapper>

                <br />
                <SpaceBetweenDiv>
                  <p>Enter Amount</p>
                  <ClickableP
                    tabIndex={0}
                    onClick={() => {
                      setAmountToStake(
                        showWaxBalance(tokenBalances, balancesAreLoading)[1]
                      );
                    }}
                  >
                    Max {showWaxBalance(tokenBalances, balancesAreLoading)[0]}
                  </ClickableP>
                </SpaceBetweenDiv>
                <FusionInputWrapper wide={true}>
                  <input
                    placeholder="WAX Amount"
                    value={amountToStake}
                    onChange={(e) => {
                      setAmountToStake(e.target.value.replace(/[^0-9.]/g, ""));
                    }}
                  />
                </FusionInputWrapper>

                <MessageWrapper>
                  <LiquifyToggleCont>
                    Liquify
                    <FusionSlider
                      onClick={() => setLiquify((prev) => !prev)}
                      toggled={liquify}
                    >
                      <FusionSliderCircle toggled={liquify} />
                    </FusionSlider>
                  </LiquifyToggleCont>
                  If you choose to liquify, you will receive lsWAX which is
                  transferable, and autocompounds your rewards. Otherwise, you
                  will receive sWAX which is non transferable and requires
                  manually claiming your WAX rewards.
                </MessageWrapper>
                <br />
                <SpaceBetweenDiv>
                  <p>Minimum Received</p>
                  <p>
                    {showOutput(
                      amountToStake,
                      liquify,
                      fusionState,
                      stateIsLoading
                    )?.minimum?.toFixed(4)}{" "}
                    {liquify ? "LSWAX" : "SWAX"}
                  </p>
                </SpaceBetweenDiv>
                <SpaceBetweenDiv>
                  <p>Exchange rate</p>
                  <p>
                    {showExchangeRate(liquify, fusionState, stateIsLoading)}
                  </p>
                </SpaceBetweenDiv>

                {isLoggedIn() && (
                  <button
                    onClick={() => {
                      stakeWax(
                        setRefresh,
                        amountToStake,
                        liquify,
                        fusionState,
                        stateIsLoading,
                        setShowTxModal,
                        setTxModalText,
                        setTxIsLoading,
                        wharfSession
                      );
                    }}
                  >
                    Stake
                  </button>
                )}

                {!isLoggedIn() && (
                  <button
                    onClick={() => {
                      logInWithWharfkit(setCurrentUser, setWharfSession);
                    }}
                  >
                    Connect Wallet
                  </button>
                )}
              </StakeContainer>
            </>
          )}

          {currentTab == "withdrawals" && (
            <>
              <FoldersContainer2024>
                <FoldersRowCentered>
                  <FolderTab2024
                    selected={withdrawTab == "request"}
                    disabled={withdrawTab == "request"}
                    onClick={() => {
                      setWithdrawTab("request");
                    }}
                  >
                    Request
                  </FolderTab2024>

                  <FolderTab2024
                    selected={withdrawTab == "instant"}
                    disabled={withdrawTab == "instant"}
                    fontWeight={600}
                    letterSpacing={"0.7px"}
                    onClick={() => {
                      setWithdrawTab("instant");
                    }}
                  >
                    Instant Redeem
                  </FolderTab2024>

                  <FolderTab2024
                    selected={withdrawTab == "claim"}
                    disabled={withdrawTab == "claim"}
                    onClick={() => {
                      setWithdrawTab("claim");
                    }}
                  >
                    Claim
                  </FolderTab2024>
                </FoldersRowCentered>
              </FoldersContainer2024>

              {withdrawTab == "request" && (
                <>
                  <div className="w-100 flex justify-center mt-6">
                    <ToggleSwitch
                      toggled={tokenToRequest == "LSWAX"}
                      onToggle={() => {
                        if (tokenToRequest == "SWAX") {
                          setTokenToRequest("LSWAX");
                        } else {
                          setTokenToRequest("SWAX");
                        }
                        setAmountToRequest("");
                      }}
                    />
                  </div>

                  <StakeContainer>
                    <SpaceBetweenDiv>
                      <p>Enter Amount</p>
                      <ClickableP
                        tabIndex={0}
                        onClick={() => {
                          if (tokenToRequest == "SWAX") {
                            setAmountToRequest(
                              Number(userStake?.swax_balance.split(" ")[0])
                            );
                          } else {
                            setAmountToRequest(
                              Number(
                                showLSWaxBalance(
                                  tokenBalances,
                                  balancesAreLoading
                                )[1]
                              )
                            );
                          }
                        }}
                      >
                        Max{" "}
                        {tokenToRequest == "SWAX"
                          ? showSwaxBalance(userStake, stakeIsLoading)
                          : showLSWaxBalance(
                              tokenBalances,
                              balancesAreLoading
                            )[0]}
                      </ClickableP>
                    </SpaceBetweenDiv>
                    <FusionInputWrapper wide={true}>
                      <input
                        placeholder={`${tokenToRequest} Amount`}
                        value={amountToRequest}
                        onChange={(e) => {
                          setAmountToRequest(
                            e.target.value.replace(/[^0-9.]/g, "")
                          );
                        }}
                      />
                    </FusionInputWrapper>
                    <br />
                    <SpaceBetweenDiv wrap={true}>
                      <UseWaxFusionCont
                        selected={withdrawFrom == "waxfusion"}
                        tabIndex="0"
                        onClick={() => {
                          if (withdrawFrom != "waxfusion") {
                            setWithdrawFrom("waxfusion");
                          }
                        }}
                      >
                        <h3>Use WaxFusion</h3>
                        <SpaceBetweenDiv top={"40px"}>
                          <p>Rate</p>
                          <p>1:1</p>
                        </SpaceBetweenDiv>

                        <SpaceBetweenDiv top={"60px"}>
                          <p>Waiting Time</p>
                          <p>1-21 Days</p>
                        </SpaceBetweenDiv>
                      </UseWaxFusionCont>

                      <UseWaxFusionCont
                        selected={withdrawFrom == "alcor"}
                        tabIndex="0"
                        onClick={() => {
                          if (withdrawFrom != "alcor") {
                           //setWithdrawFrom("alcor");
                          }
                        }}
                      >
                        <h3>Use Alcor</h3>
                        <SpaceBetweenDiv top={"40px"}>
                          <p>Rate</p>
                          <p>Market</p>
                        </SpaceBetweenDiv>

                        <SpaceBetweenDiv top={"60px"}>
                          <p>Waiting Time</p>
                          <p>Instant</p>
                        </SpaceBetweenDiv>
                      </UseWaxFusionCont>
                    </SpaceBetweenDiv>

                    {withdrawFrom == "waxfusion" && (
                      <>
                        <MessageWrapper>
                          {epochsAreLoading && "Loading redemption pool data"}
                          {!epochsAreLoading &&
                            epochs?.length > 0 &&
                            showRedemptionPools(epochs)}
                          {!epochsAreLoading &&
                            epochs?.length == 0 &&
                            "Error fetching redemption pool data"}
                        </MessageWrapper>
                        <br />

                        <SpaceBetweenDiv>
                          <p>
                            {tokenToRequest == "SWAX"
                              ? "You will receive"
                              : "Minimum received"}
                          </p>
                          <p>
                            {tokenToRequest == "SWAX"
                              ? Number(amountToRequest).toFixed(2)
                              : Math.floor(
                                  showUnliquifyOutput(
                                    amountToRequest,
                                    fusionState,
                                    stateIsLoading
                                  ).minimum * 1e8
                                ) / 1e8}{" "}
                            WAX
                          </p>
                        </SpaceBetweenDiv>
                        <SpaceBetweenDiv>
                          <p>Exchange rate</p>
                          <p>
                            {tokenToRequest == "SWAX"
                              ? "1 SWAX = 1"
                              : `1 LSWAX = 
                          ${getSWaxPerLSWax(
                            fusionState,
                            stateIsLoading
                          ).toFixed(4)}
                          `}{" "}
                            WAX
                          </p>
                        </SpaceBetweenDiv>
                      </>
                    )}

                    {withdrawFrom == "alcor" && (
                      <>
                        <MessageWrapper>
                          Note that when you use Alcor, you are trading your
                          tokens on the open market. This means that you are
                          subject to slippage and market fees.
                        </MessageWrapper>
                        <br />
                        <SpaceBetweenDiv>
                          <p>Price Impact</p>
                          <p>
                            {" "}
                            {showPriceImpact(
                              swapRateIsLoading,
                              amountToRequest,
                              outputAmount,
                              priceImpact
                            )}
                          </p>
                        </SpaceBetweenDiv>
                        <SpaceBetweenDiv>
                          <p>Minimum Received</p>
                          <p>
                            {" "}
                            {showMinReceived(
                              swapRateIsLoading,
                              tokenToRequest == "LSWAX"
                                ? amountToRequest
                                : showOutput(
                                    amountToRequest,
                                    true,
                                    fusionState,
                                    stateIsLoading
                                  )?.minimum?.toFixed(4),
                              outputAmount,
                              minReceived
                            )}
                          </p>
                        </SpaceBetweenDiv>
                        <SpaceBetweenDiv>
                          <p>Exchange rate</p>
                          <p>
                            {showSwapRate(
                              swapRateIsLoading,
                              tokenToRequest == "LSWAX"
                                ? amountToRequest
                                : showOutput(
                                    amountToRequest,
                                    true,
                                    fusionState,
                                    stateIsLoading
                                  )?.minimum?.toFixed(4),
                              outputAmount,
                              tokenToRequest
                            )}
                          </p>
                        </SpaceBetweenDiv>
                      </>
                    )}

                    {withdrawFrom == "waxfusion" && (
                      <MessageWrapper>
                        I understand that submitting a new withdrawal request
                        will replace any previous withdrawal requests that I
                        have made.
                        <CheckboxContainer>
                          <div
                            tabIndex={0}
                            onClick={() => {
                              setAcceptReplacingPrev((prev) => !prev);
                            }}
                          >
                            {acceptReplacingPrev && <>&nbsp;&#x2713;</>}
                          </div>
                        </CheckboxContainer>
                      </MessageWrapper>
                    )}

                    {!isLoggedIn() && (
                      <button
                        onClick={() => {
                          logInWithWharfkit(setCurrentUser, setWharfSession);
                        }}
                      >
                        Connect Wallet
                      </button>
                    )}

                    {isLoggedIn() && withdrawFrom == "waxfusion" && (
                      <button
                        disabled={
                          amountToRequest == "" ||
                          !isValidNumber(amountToRequest) ||
                          !acceptReplacingPrev
                        }
                        onClick={() => {
                          reqredeem(
                            setRefresh,
                            setRefreshRedemptionPool,
                            acceptReplacingPrev,
                            amountToRequest,
                            withdrawFrom,
                            tokenToRequest,
                            Math.floor(
                              showUnliquifyOutput(
                                amountToRequest,
                                fusionState,
                                stateIsLoading
                              ).minimum * 1e8
                            ) / 1e8,
                            Math.floor(
                              showUnliquifyOutput(
                                amountToRequest,
                                fusionState,
                                stateIsLoading
                              ).minimum * 1e8
                            ) / 1e8,
                            setShowTxModal,
                            setTxModalText,
                            setTxIsLoading,
                            wharfSession
                          );
                        }}
                      >
                        {!acceptReplacingPrev &&
                          amountToRequest != "" &&
                          isValidNumber(amountToRequest) &&
                          "Accept Terms"}
                        {(amountToRequest == "" ||
                          !isValidNumber(amountToRequest)) &&
                          "Enter Amount"}
                        {amountToRequest != "" &&
                          isValidNumber(amountToRequest) &&
                          acceptReplacingPrev &&
                          "Submit Request"}
                      </button>
                    )}
                  </StakeContainer>
                </>
              )}

              {withdrawTab == "claim" && (
                <>
                  {requestsAreLoading && (
                    <MessageWrapper>Fetching your requests...</MessageWrapper>
                  )}

                  {!requestsAreLoading && requests.length == 0 && (
                    <MessageWrapper>{showRedeemMessage()}</MessageWrapper>
                  )}

                  {!requestsAreLoading &&
                    requests.length > 0 &&
                    requests.some((req) => claimInfo(req.epoch_id).expired) && (
                      <StakeContainer>
                        <MessageWrapper>
                          You have expired requests. Would you like to delete
                          them and free up your RAM?
                        </MessageWrapper>
                        <button
                          onClick={() => {
                            clearExpiredTx(
                              setShowTxModal,
                              setTxModalText,
                              setTxIsLoading,
                              wharfSession
                            );
                          }}
                        >
                          Yes, Delete
                        </button>
                      </StakeContainer>
                    )}

                  {!requestsAreLoading &&
                    requests.length > 0 &&
                    requests.map((req, index) => (
                      <StakeContainer key={index}>
                        <LogoPlusHeaderWrapper>
                          <h3>Epoch {req.epoch_id}</h3>
                        </LogoPlusHeaderWrapper>
                        <br />
                        <SpaceBetweenDiv>
                          <p>Amount Requested</p>
                          <p>{req.wax_amount_requested}</p>
                        </SpaceBetweenDiv>

                        {!claimInfo(req.epoch_id).expired &&
                          !claimInfo(req.epoch_id).claimable && (
                            <SpaceBetweenDiv>
                              <p>Claimable In</p>
                              <p>
                                {claimInfo(req.epoch_id).hoursLeftBeforeClaim}{" "}
                                Hours
                              </p>
                            </SpaceBetweenDiv>
                          )}

                        {!claimInfo(req.epoch_id).expired &&
                          claimInfo(req.epoch_id).claimable && (
                            <SpaceBetweenDiv>
                              <p>Must Claim Within</p>
                              <p>
                                {claimInfo(req.epoch_id).hoursBeforeExpires}{" "}
                                Hours
                              </p>
                            </SpaceBetweenDiv>
                          )}

                        {claimInfo(req.epoch_id).expired && (
                          <MessageWrapper>
                            The redemption period for this request has passed
                            already. Redemption requests must be claimed within
                            the 48 hour redemption window.
                          </MessageWrapper>
                        )}

                        {claimInfo(req.epoch_id).claimable && (
                          <button
                            onClick={() => {
                              redeemTx(
                                setRefresh,
                                setShowTxModal,
                                setTxModalText,
                                setTxIsLoading,
                                wharfSession
                              );
                            }}
                          >
                            Claim Now
                          </button>
                        )}
                      </StakeContainer>
                    ))}
                </>
              )}

              {withdrawTab == "instant" && (
                <>
                  <div className="w-100 flex justify-center mt-6">
                    <ToggleSwitch
                      toggled={tokenToRequest == "LSWAX"}
                      onToggle={() => {
                        if (tokenToRequest == "SWAX") {
                          setTokenToRequest("LSWAX");
                        } else {
                          setTokenToRequest("SWAX");
                        }
                        setInstantRedeemAmount("");
                      }}
                    />
                  </div>

                  <StakeContainer>
                    <SpaceBetweenDiv>
                      <p>Enter Amount</p>
                      <ClickableP
                        tabIndex={0}
                        onClick={() => {
                          if (tokenToRequest == "SWAX") {
                            setInstantRedeemAmount(
                              Number(userStake?.swax_balance.split(" ")[0])
                            );
                          } else {
                            setInstantRedeemAmount(
                              Number(
                                showLSWaxBalance(
                                  tokenBalances,
                                  balancesAreLoading
                                )[1]
                              )
                            );
                          }
                        }}
                      >
                        Max{" "}
                        {tokenToRequest == "SWAX"
                          ? showSwaxBalance(userStake, stakeIsLoading)
                          : showLSWaxBalance(
                              tokenBalances,
                              balancesAreLoading
                            )[0]}
                      </ClickableP>
                    </SpaceBetweenDiv>
                    <FusionInputWrapper wide={true}>
                      <input
                        placeholder={`${tokenToRequest} Amount`}
                        value={instantRedeemAmount}
                        onChange={(e) => {
                          setInstantRedeemAmount(
                            e.target.value.replace(/[^0-9.]/g, "")
                          );
                        }}
                      />
                    </FusionInputWrapper>
                    <br />
                    <SpaceBetweenDiv>
                      <p>Protocol Funds</p>
                      <p>
                        {showRentalPool(fusionState, stateIsLoading)[0]} WAX
                      </p>
                    </SpaceBetweenDiv>

                    <MessageWrapper>
                      Note: The instant redeem option has a 0.05% protocol fee,
                      which gets treated the same as all other revenue generated
                      by WaxFusion. If you want to redeem without the fee, use
                      the Request tab to add your redemption request into the
                      queue.
                    </MessageWrapper>

                    <br />
                    <SpaceBetweenDiv>
                      <p>
                        {tokenToRequest == "SWAX"
                          ? "You will receive"
                          : "Minimum received"}
                      </p>
                      <p>
                        {tokenToRequest == "SWAX"
                          ? `${
                              Math.floor(
                                Number(
                                  instantRedeemAmount *
                                    Number(1 * 0.9995) *
                                    1000
                                )
                              ) / 1000
                            }`
                          : Math.floor(
                              showUnliquifyOutput(
                                instantRedeemAmount * Number(1 * 0.9995),
                                fusionState,
                                stateIsLoading
                              ).minimum * 1e8
                            ) / 1e8}{" "}
                        WAX
                      </p>
                    </SpaceBetweenDiv>
                    <SpaceBetweenDiv>
                      <p>Exchange rate</p>
                      <p>
                        {tokenToRequest == "SWAX"
                          ? `1 SWAX = ${Number(1 * 0.9995)}`
                          : `1 LSWAX = 
                          ${(
                            getSWaxPerLSWax(fusionState, stateIsLoading) *
                            0.9995
                          ).toFixed(4)}
                          `}{" "}
                        WAX
                      </p>
                    </SpaceBetweenDiv>

                    {((tokenToRequest == "LSWAX" &&
                      showUnliquifyOutput(
                        instantRedeemAmount,
                        fusionState,
                        stateIsLoading
                      ).expected >
                        showRentalPool(fusionState, stateIsLoading)[1]) ||
                      (tokenToRequest == "SWAX" &&
                        instantRedeemAmount >
                          showRentalPool(fusionState, stateIsLoading)[1])) && (
                      <MessageWrapper>
                        You are trying to withdraw more funds than the amount
                        available in the instant redemption pool. Currently
                        there is only{" "}
                        {showRentalPool(fusionState, stateIsLoading)[1]} WAX
                        available. You can either choose a different redemption
                        option, try redeeming less, or wait until more funds are
                        added to the instant redemption pool.
                      </MessageWrapper>
                    )}

                    {isLoggedIn() ? (
                      <button
                        disabled={
                          tokenToRequest == "SWAX"
                            ? instantRedeemAmount >
                              showRentalPool(fusionState, stateIsLoading)[1]
                            : showUnliquifyOutput(
                                instantRedeemAmount,
                                fusionState,
                                stateIsLoading
                              ).expected >
                              showRentalPool(fusionState, stateIsLoading)[1]
                        }
                        onClick={() => {
                          instaRedeem(
                            setRefresh,
                            instantRedeemAmount,
                            tokenToRequest,
                            Math.floor(
                              showUnliquifyOutput(
                                instantRedeemAmount,
                                fusionState,
                                stateIsLoading
                              ).expected * 1e8
                            ) / 1e8,
                            Math.floor(
                              showUnliquifyOutput(
                                instantRedeemAmount,
                                fusionState,
                                stateIsLoading
                              ).minimum * 1e8
                            ) / 1e8,
                            setShowTxModal,
                            setTxModalText,
                            setTxIsLoading,
                            wharfSession
                          );
                        }}
                      >
                        {tokenToRequest == "LSWAX"
                          ? showUnliquifyOutput(
                              instantRedeemAmount,
                              fusionState,
                              stateIsLoading
                            ).expected >
                            showRentalPool(fusionState, stateIsLoading)[1]
                            ? "Insufficient Funds"
                            : "Redeem Now"
                          : instantRedeemAmount >
                            showRentalPool(fusionState, stateIsLoading)[1]
                          ? "Insufficient Funds"
                          : "Redeem Now"}
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          logInWithWharfkit(setCurrentUser, setWharfSession);
                        }}
                      >
                        Connect Wallet
                      </button>
                    )}
                  </StakeContainer>
                </>
              )}
            </>
          )}

          {currentTab == "rewards" && (
            <StakeContainer>
              <LogoPlusHeaderWrapper>
                {tokens_svg}
                <h2>Rewards</h2>
              </LogoPlusHeaderWrapper>

              <br />

              {!rewardsAreLoading && claimableRewards > 0 && (
                <>
                  <MessageWrapper>
                    You have {claimableRewards} WAX to claim!
                  </MessageWrapper>
                  <br />

                  <SpaceBetweenDiv>
                    <p>Claim as WAX</p>
                    <CheckboxContainer>
                      <div
                        tabIndex={0}
                        onClick={() => {
                          claimAs != "WAX" && setClaimAs("WAX");
                        }}
                      >
                        {claimAs == "WAX" && <>&#x2713;</>}
                      </div>
                    </CheckboxContainer>
                  </SpaceBetweenDiv>
                  <SpaceBetweenDiv>
                    <p>Claim as sWAX</p>
                    <CheckboxContainer>
                      <div
                        tabIndex={0}
                        onClick={() => {
                          claimAs != "SWAX" && setClaimAs("SWAX");
                        }}
                      >
                        {claimAs == "SWAX" && <>&#x2713;</>}
                      </div>
                    </CheckboxContainer>
                  </SpaceBetweenDiv>
                  <SpaceBetweenDiv>
                    <p>Claim as lsWAX</p>
                    <CheckboxContainer>
                      <div
                        tabIndex={0}
                        onClick={() => {
                          claimAs != "LSWAX" && setClaimAs("LSWAX");
                        }}
                      >
                        {claimAs == "LSWAX" && <>&#x2713;</>}
                      </div>
                    </CheckboxContainer>
                  </SpaceBetweenDiv>

                  <br />
                  {claimAs != "LSWAX" ? (
                    <SpaceBetweenDiv>
                      <p>You will receive</p>
                      <p>
                        {claimableRewards} {claimAs}
                      </p>
                    </SpaceBetweenDiv>
                  ) : (
                    <>
                      <SpaceBetweenDiv>
                        <p>Minimum Received</p>
                        <p>
                          {Math.floor(
                            showOutputForClaiming(
                              claimableRewards,
                              claimAs,
                              fusionState,
                              stateIsLoading
                            ).minimum * 1e8
                          ) / 1e8}{" "}
                          LSWAX
                        </p>
                      </SpaceBetweenDiv>
                    </>
                  )}

                  <SpaceBetweenDiv>
                    <p>Exchange rate</p>
                    <p>
                      {showExchangeRateForClaiming(
                        claimAs,
                        fusionState,
                        stateIsLoading
                      )}
                    </p>
                  </SpaceBetweenDiv>

                  <button
                    onClick={() => {
                      claimRewards(
                        setRefresh,
                        claimAs,
                        Math.floor(
                          showOutputForClaiming(
                            claimableRewards,
                            claimAs,
                            fusionState,
                            stateIsLoading
                          ).expected * 1e8
                        ) / 1e8,
                        setShowTxModal,
                        setTxModalText,
                        setTxIsLoading,
                        wharfSession
                      );
                    }}
                  >
                    Claim
                  </button>
                </>
              )}

              {!rewardsAreLoading &&
                (claimableRewards == 0 || isNaN(claimableRewards)) && (
                  <MessageWrapper>
                    You don't have any rewards to claim.
                  </MessageWrapper>
                )}
            </StakeContainer>
          )}

          {currentTab == "liquify" && (
            <>
              <FoldersContainer2024>
                <FoldersRowCentered>
                  <FolderTab2024
                    selected={liquifyTab == "liquify"}
                    disabled={liquifyTab == "liquify"}
                    onClick={() => {
                      setLiquifyTab("liquify");
                    }}
                  >
                    Liquify
                  </FolderTab2024>

                  <FolderTab2024
                    selected={liquifyTab == "unliquify"}
                    disabled={liquifyTab == "unliquify"}
                    onClick={() => {
                      setLiquifyTab("unliquify");
                    }}
                  >
                    Unliquify
                  </FolderTab2024>
                </FoldersRowCentered>
              </FoldersContainer2024>

              {liquifyTab == "liquify" && (
                <>
                  <StakeContainer>
                    <SpaceBetweenDiv>
                      <p>Enter Amount</p>
                      <ClickableP
                        tabIndex="0"
                        onClick={() => {
                          setAmountToLiquify(
                            Number(userStake?.swax_balance.split(" ")[0])
                          );
                        }}
                      >
                        Max {showSwaxBalance(userStake, stakeIsLoading)}
                      </ClickableP>
                    </SpaceBetweenDiv>
                    <FusionInputWrapper wide={true}>
                      <input
                        placeholder="SWAX Amount"
                        value={amountToLiquify}
                        onChange={(e) => {
                          setAmountToLiquify(
                            e.target.value.replace(/[^0-9.]/g, "")
                          );
                        }}
                      />
                    </FusionInputWrapper>

                    <br />
                    <SpaceBetweenDiv>
                      <p>Expected Output</p>
                      <p>
                        {Math.floor(
                          showOutput(
                            amountToLiquify,
                            true,
                            fusionState,
                            stateIsLoading
                          ).expected * 1e8
                        ) / 1e8}{" "}
                        LSWAX
                      </p>
                    </SpaceBetweenDiv>
                    <SpaceBetweenDiv>
                      <p>Exchange rate</p>
                      <p>
                        {showExchangeRateForLiquifying(
                          fusionState,
                          stateIsLoading
                        )}
                      </p>
                    </SpaceBetweenDiv>

                    <button
                      onClick={() => {
                        liquifyTx(
                          setRefresh,
                          amountToLiquify,
                          Math.floor(
                            showOutput(
                              amountToLiquify,
                              true,
                              fusionState,
                              stateIsLoading
                            ).expected * 1e8
                          ) / 1e8,
                          setShowTxModal,
                          setTxModalText,
                          setTxIsLoading,
                          wharfSession
                        );
                      }}
                    >
                      Liquify Now
                    </button>
                  </StakeContainer>
                </>
              )}

              {liquifyTab == "unliquify" && (
                <>
                  <StakeContainer>
                    <SpaceBetweenDiv>
                      <p>Enter Amount</p>
                      <ClickableP
                        onClick={() => {
                          setAmountToUnliquify(
                            showLSWaxBalance(
                              tokenBalances,
                              balancesAreLoading
                            )[1]
                          );
                        }}
                      >
                        Max{" "}
                        {showLSWaxBalance(tokenBalances, balancesAreLoading)[0]}
                      </ClickableP>
                    </SpaceBetweenDiv>
                    <FusionInputWrapper wide={true}>
                      <input
                        placeholder="LSWAX Amount"
                        value={amountToUnliquify}
                        onChange={(e) => {
                          setAmountToUnliquify(
                            e.target.value.replace(/[^0-9.]/g, "")
                          );
                        }}
                      />
                    </FusionInputWrapper>

                    <br />
                    <SpaceBetweenDiv>
                      <p>Minimum Received</p>
                      <p>
                        {Math.floor(
                          showUnliquifyOutput(
                            amountToUnliquify,
                            fusionState,
                            stateIsLoading
                          ).minimum * 1e8
                        ) / 1e8}{" "}
                        SWAX
                      </p>
                    </SpaceBetweenDiv>
                    <SpaceBetweenDiv>
                      <p>Exchange Rate</p>
                      <p>
                        1 LSWAX ={" "}
                        {getSWaxPerLSWax(fusionState, stateIsLoading).toFixed(
                          4
                        )}{" "}
                        SWAX
                      </p>
                    </SpaceBetweenDiv>

                    <button
                      onClick={() => {
                        unliquify(
                          setRefresh,
                          amountToUnliquify,
                          Math.floor(
                            showUnliquifyOutput(
                              amountToUnliquify,
                              fusionState,
                              stateIsLoading
                            ).expected * 1e8
                          ) / 1e8,
                          setShowTxModal,
                          setTxModalText,
                          setTxIsLoading,
                          wharfSession
                        );
                      }}
                    >
                      Unliquify Now
                    </button>
                  </StakeContainer>
                </>
              )}
            </>
          )}
        </PageBody2024>
      </PageWrapper2024>
    </div>
  );
};

export default Stake;
