import {
  currentUsername,
} from ".././config";

export const getAssetSymbol = (asset) => {
  let symbol = asset.substring(asset.indexOf(" ") + 1)

  return symbol;
}

export const getAssetQuantity = (asset) => {
  let quantity = asset.substring(0, asset.indexOf(" "))

  return quantity;
}

export const isLoggedIn = () => {
  if(currentUsername == null || currentUsername == "" || currentUsername.length < 2){
    return false
  }

  return true
}