import { useState } from "react";
import { TESTNET, currentUsername, mainnetLightApiList, testnetLightApiList } from "../../data/config";
import axios from 'axios';
import { isLoggedIn } from "../../data/functions/wallet_functions";

export const useGetTokenBalsFromLightApi = () => {
    
    const [tokenBalances, setTokenBalances] = useState([]);
    const [balancesAreLoading, setBalancesAreLoading] = useState(true);

    let apiList = TESTNET ? testnetLightApiList : mainnetLightApiList;
    let networkName = TESTNET ? "waxtest" : "wax";

    const getTokenBalances = async () => {
        if(!isLoggedIn()) { return; }

        setBalancesAreLoading(true);

        for (const api of apiList) {

            try {
                const res = await axios.get(`${api}/api/balances/${networkName}/${currentUsername}?pretty=1`, {});

                if (res.data && res.data.balances) {
                    setTokenBalances(res.data.balances)
                }

                break;

            } catch (error) {
                console.log(`An error occurred with ${api}. Trying next API...`);
                console.error(error);
            }
        }
        
        setBalancesAreLoading(false);
    }

    return [tokenBalances, getTokenBalances, balancesAreLoading]
}
