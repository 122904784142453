import { ModalErrorCont, ModalSuccessCont } from "../../Styles2024";
import { showOutput } from "../../pages/Stake";
import {
  DAPP_CONTRACT,
  DEFAULT_SLIPPAGE_TOLERANCE,
  POL_CONTRACT,
  TOKEN_CONTRACT,
  defaultSpinnerDuration,
  defaultTxSettings,
} from "../config";
import { PROCESSING_TX_TEXT, error_svg, success_svg } from "../constants";

export const getLsWaxPerSWax = (s, loading) => {
  if(loading) return 0;

  let lsWAX_per_sWAX;

	if( s?.liquified_swax?.split(" ")[0] == 0 && s?.swax_currently_backing_lswax?.split(" ")[0] == 0 ){
		lsWAX_per_sWAX = 1.0;
	} else {
		lsWAX_per_sWAX = s?.liquified_swax?.split(" ")[0] / s?.swax_currently_backing_lswax?.split(" ")[0];
	}

  return Number(lsWAX_per_sWAX);

}

export const getSWaxPerLSWax = (s, loading) => {
  if(loading) return 0;

  let sWAX_per_lsWAX;

	if( s?.liquified_swax?.split(" ")[0] == 0 && s?.swax_currently_backing_lswax?.split(" ")[0] == 0 ){
		sWAX_per_lsWAX = 1.0;
	} else {
		sWAX_per_lsWAX = s?.swax_currently_backing_lswax?.split(" ")[0] / s?.liquified_swax?.split(" ")[0]
	}

  return Number(sWAX_per_lsWAX);

}

export const stakeWax = async (
  setRefresh,
  amount,
  liquify,
  s,
  loading,
  setShowTxModal,
  setTxModalText,
  setTxIsLoading,
  wharfSession
) => {
  setShowTxModal(true);
  setTxModalText("Awaiting confirmation...");

  if (localStorage.getItem("wharf--session") == null) {
    setTxModalText(
      "You are not logged in. Click the wallet icon in the top menu"
    );
    return;
  }

  const session = wharfSession;

  let amountToStake = Number(amount).toFixed(8) + " WAX";
  let sWaxAmount = Number(amount).toFixed(8) + " SWAX";
  let expected_output = `${  (Math.floor(showOutput(
    amount, liquify, s, loading
  )?.expected * 1e8) / 1e8).toFixed(8) } LSWAX`;

  let actions = [
    {
      account: DAPP_CONTRACT,
      name: "stake",
      authorization: [session.permissionLevel],
      data: {
        user: session.actor,
      },
    },
    {
      account: "eosio.token",
      name: "transfer",
      authorization: [session.permissionLevel],
      data: {
        from: session.actor,
        to: DAPP_CONTRACT,
        quantity: amountToStake,
        memo: "stake",
      },
    },
  ];

  if (liquify) {
    actions.push({
      account: DAPP_CONTRACT,
      name: "liquifyexact",
      authorization: [session.permissionLevel],
      data: {
        user: session.actor,
        quantity: sWaxAmount,
        minimum_output: expected_output
      },
    });
  }

  try {
    const result = await session.transact(
      { actions: actions },
      defaultTxSettings
    );
    setTxIsLoading(true);
    setTxModalText(PROCESSING_TX_TEXT);
    const timer = setTimeout(() => {
      setTxModalText(<span>
            <ModalSuccessCont>
            {success_svg}
            </ModalSuccessCont>
            Your WAX has been staked{liquify && " and liquified"}
          </span>)
      setTxIsLoading(false);
      setRefresh((prev) => !prev);
    }, defaultSpinnerDuration);
    return () => clearTimeout(timer);
  } catch (e) {
    console.log("ERROR: ", e);
    setTxModalText(<span>
        <ModalErrorCont>
        {error_svg}
        </ModalErrorCont>
        {e.message}        
      </span>
        );
  }
};


export const unliquify = async (
    setRefresh,
    amount,
    expected,
    setShowTxModal,
    setTxModalText,
    setTxIsLoading,
    wharfSession
  ) => {
    setShowTxModal(true);
    setTxModalText("Awaiting confirmation...");
  
    if (localStorage.getItem("wharf--session") == null) {
      setTxModalText(
        "You are not logged in. Click the wallet icon in the top menu"
      );
      return;
    }
  
    const session = wharfSession;
  
    let amountToUnliquify = Number(amount).toFixed(8) + " LSWAX";
    let expectedOutput = Math.round(Number(expected) * 100000000);
  
    let actions = [
      {
        account: TOKEN_CONTRACT,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: DAPP_CONTRACT,
          quantity: amountToUnliquify,
          memo: `|unliquify_exact|${expectedOutput}|`,
        },
      },
    ];
  
  
    try {
      const result = await session.transact(
        { actions: actions },
        defaultTxSettings
      );
      setTxIsLoading(true);
      setTxModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setTxModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              Your LSWAX has been converted to SWAX.
            </span>)
        setTxIsLoading(false);
        setRefresh((prev) => !prev);
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log("ERROR: ", e);
      setTxModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
    }
  };

  export const liquifyTx = async (
    setRefresh,
    amount,
    expected,
    setShowTxModal,
    setTxModalText,
    setTxIsLoading,
    wharfSession
  ) => {
    setShowTxModal(true);
    setTxModalText("Awaiting confirmation...");
  
    if (localStorage.getItem("wharf--session") == null) {
      setTxModalText(
        "You are not logged in. Click the wallet icon in the top menu"
      );
      return;
    }
  
    const session = wharfSession;
  
    let amountToLiquify = Number(amount).toFixed(8) + " SWAX";
    let expectedOutput = Number(expected).toFixed(8) + " LSWAX";
  
    let actions = [
        {
            account: DAPP_CONTRACT,
            name: "liquifyexact",
            authorization: [session.permissionLevel],
            data: {
              user: session.actor,
              quantity: amountToLiquify,
              minimum_output: expectedOutput
            },
          }
    ];
  
  
    try {
      const result = await session.transact(
        { actions: actions },
        defaultTxSettings
      );
      setTxIsLoading(true);
      setTxModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setTxModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              Your SWAX has been converted to LSWAX.
            </span>)
        setTxIsLoading(false);
        setRefresh((prev) => !prev);
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log("ERROR: ", e);
      setTxModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
    }
  };  


  export const rentCpu = async (
    setRefresh,
    setRefreshRentals,
    amount,
    amountToSend,
    receiver,
    epoch,
    setShowTxModal,
    setTxModalText,
    setTxIsLoading,
    wharfSession
  ) => {
    setShowTxModal(true);
    setTxModalText("Awaiting confirmation...");
  
    if (localStorage.getItem("wharf--session") == null) {
      setTxModalText(
        "You are not logged in. Click the wallet icon in the top menu"
      );
      return;
    }
  
    const session = wharfSession;
  
    let actions = [
      {
        account: "eosio.token",
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: DAPP_CONTRACT,
          quantity: `${amountToSend} WAX`,
          memo: `|rent_cpu|${receiver}|${amount}|${epoch}|`,
        },
      },
    ];
  
    try {
      const result = await session.transact(
        { actions: actions },
        defaultTxSettings
      );
      setTxIsLoading(true);
      setTxModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setTxModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
            {amount} WAX has been staked to {receiver}.
            </span>)
        setTxIsLoading(false);
        setRefresh((prev) => !prev);
        setRefreshRentals((prev) => !prev);
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log("ERROR: ", e);
      setTxModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
    }
  };

  export const rentFromPOL = async (
    setRefresh,
    amount,
    amountToSend,
    receiver,
    daysToRent,
    setShowTxModal,
    setTxModalText,
    setTxIsLoading,
    wharfSession
  ) => {
    setShowTxModal(true);
    setTxModalText("Awaiting confirmation...");
  
    if (localStorage.getItem("wharf--session") == null) {
      setTxModalText(
        "You are not logged in. Click the wallet icon in the top menu"
      );
      return;
    }
  
    const session = wharfSession;
  
    let actions = [
      {
        account: POL_CONTRACT,
        name: "rentcpu",
        authorization: [session.permissionLevel],
        data: {
          renter: session.actor,
          cpu_receiver: receiver,
        },
      },
      {
        account: "eosio.token",
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: POL_CONTRACT,
          quantity: `${amountToSend} WAX`,
          memo: `|rent_cpu|${receiver}|${daysToRent}|${amount}|`,
        },
      },
    ];
  
    try {
      const result = await session.transact(
        { actions: actions },
        defaultTxSettings
      );
      setTxIsLoading(true);
      setTxModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setTxModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
            {amount} WAX has been staked to {receiver}.
            </span>)
        setTxIsLoading(false);
        setRefresh((prev) => !prev);
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log("ERROR: ", e);
      setTxModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
    }
  };  

  export const extendPolRental = async (
    setRefresh,
    amount,
    amountToSend,
    receiver,
    daysToRent,
    setShowTxModal,
    setTxModalText,
    setTxIsLoading,
    wharfSession
  ) => {
    setShowTxModal(true);
    setTxModalText("Awaiting confirmation...");
  
    if (localStorage.getItem("wharf--session") == null) {
      setTxModalText(
        "You are not logged in. Click the wallet icon in the top menu"
      );
      return;
    }
  
    const session = wharfSession;
  
    let actions = [
      {
        account: "eosio.token",
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: POL_CONTRACT,
          quantity: `${amountToSend} WAX`,
          memo: `|extend_rental|${receiver}|${daysToRent}|`,
        },
      },
    ];
  
    try {
      const result = await session.transact(
        { actions: actions },
        defaultTxSettings
      );
      setTxIsLoading(true);
      setTxModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setTxModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
            Your rental has been extened by {daysToRent} days.
            </span>)
        setTxIsLoading(false);
        setRefresh((prev) => !prev);
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log("ERROR: ", e);
      setTxModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
    }
  };  


  export const increasePolRental = async (
    setRefresh,
    amount,
    amountToSend,
    receiver,
    setShowTxModal,
    setTxModalText,
    setTxIsLoading,
    wharfSession
  ) => {
    setShowTxModal(true);
    setTxModalText("Awaiting confirmation...");
  
    if (localStorage.getItem("wharf--session") == null) {
      setTxModalText(
        "You are not logged in. Click the wallet icon in the top menu"
      );
      return;
    }
  
    const session = wharfSession;
  
    let actions = [
      {
        account: "eosio.token",
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: POL_CONTRACT,
          quantity: `${amountToSend} WAX`,
          memo: `|increase_rental|${receiver}|${amount}|`,
        },
      },
    ];
  
    try {
      const result = await session.transact(
        { actions: actions },
        defaultTxSettings
      );
      setTxIsLoading(true);
      setTxModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setTxModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
            Your rental has been increased by {amount} WAX.
            </span>)
        setTxIsLoading(false);
        setRefresh((prev) => !prev);
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log("ERROR: ", e);
      setTxModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
    }
  };  

  export const reqredeem = async (
    setRefresh,
    setRefreshRedemptionPool,
    acceptReplacingPrev,
    amount,
    withdrawFrom,
    tokenToRequest,
    expected,
    minimum,
    setShowTxModal,
    setTxModalText,
    setTxIsLoading,
    wharfSession
  ) => {
    setShowTxModal(true);
    setTxModalText("Awaiting confirmation...");
  
    if (localStorage.getItem("wharf--session") == null) {
      setTxModalText(
        "You are not logged in. Click the wallet icon in the top menu"
      );
      return;
    }
  
    const session = wharfSession;

    let amountToUnliquify = Number(amount).toFixed(8) + " LSWAX";
    let expectedOutput = Math.round(Number(expected) * 100000000);   
    
    let actions = [];  
    let success_message;

    if(tokenToRequest == "LSWAX"){
      actions.push(      {
        account: TOKEN_CONTRACT,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: DAPP_CONTRACT,
          quantity: amountToUnliquify,
          memo: `|unliquify_exact|${expectedOutput}|`,
        },
      })

    }
    
    if(withdrawFrom == "waxfusion"){
      actions.push({
        account: DAPP_CONTRACT,
        name: "reqredeem",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          swax_to_redeem: `${tokenToRequest == "SWAX" ? Number(amount).toFixed(8) : Number(minimum).toFixed(8)} SWAX`,
          accept_replacing_prev_requests: acceptReplacingPrev
        },
      })

      success_message = "Your withdrawal has been requested"
    }
  
  
    try {
      const result = await session.transact(
        { actions: actions },
        defaultTxSettings
      );
      setTxIsLoading(true);
      setTxModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setTxModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              {success_message}
            </span>)
        setTxIsLoading(false);
        setRefresh((prev) => !prev);
        setRefreshRedemptionPool(true)
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log("ERROR: ", e);
      setTxModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
    }
  };


  export const instaRedeem = async (
    setRefresh,
    amount,
    tokenToRequest,
    expected,
    minimum,
    setShowTxModal,
    setTxModalText,
    setTxIsLoading,
    wharfSession
  ) => {
    setShowTxModal(true);
    setTxModalText("Awaiting confirmation...");
  
    if (localStorage.getItem("wharf--session") == null) {
      setTxModalText(
        "You are not logged in. Click the wallet icon in the top menu"
      );
      return;
    }
  
    const session = wharfSession;
    
    let actions = [];  
    let success_message;
    
    let amountToUnliquify = Number(amount).toFixed(8) + " LSWAX";
    let expectedOutput = Math.round(Number(expected) * 100000000);

    actions.push(
      {
        account: DAPP_CONTRACT,
        name: "stake",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor
        },
      },
    )    

    if(tokenToRequest == "LSWAX"){
      //add unliquify transfer
      actions.push(      {
        account: TOKEN_CONTRACT,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: DAPP_CONTRACT,
          quantity: amountToUnliquify,
          memo: `|unliquify_exact|${expectedOutput}|`,
        },
      })
    }

    let swaxToRedeemAmount = `${Number(amount).toFixed(8)} SWAX`

    if(tokenToRequest == "LSWAX"){
      swaxToRedeemAmount = `${Number(minimum).toFixed(8)} SWAX`
    }


      actions.push(
        {
          account: DAPP_CONTRACT,
          name: "instaredeem",
          authorization: [session.permissionLevel],
          data: {
            user: session.actor,
            swax_to_redeem: swaxToRedeemAmount
          },
        },
      )

      success_message = "Your redemption has been completed"
  
    try {
      const result = await session.transact(
        { actions: actions },
        defaultTxSettings
      );
      setTxIsLoading(true);
      setTxModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setTxModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              {success_message}
            </span>)
        setTxIsLoading(false);
        setRefresh((prev) => !prev);
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log("ERROR: ", e);
      setTxModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
    }
  };  


  export const redeemTx = async (
    setRefresh,
    setShowTxModal,
    setTxModalText,
    setTxIsLoading,
    wharfSession
  ) => {
    setShowTxModal(true);
    setTxModalText("Awaiting confirmation...");
  
    if (localStorage.getItem("wharf--session") == null) {
      setTxModalText(
        "You are not logged in. Click the wallet icon in the top menu"
      );
      return;
    }
  
    const session = wharfSession;

    
    let actions = [
        {
          account: DAPP_CONTRACT,
          name: "redeem",
          authorization: [session.permissionLevel],
          data: {
            user: session.actor
          },
        },
      ];
  
  
    try {
      const result = await session.transact(
        { actions: actions },
        defaultTxSettings
      );
      setTxIsLoading(true);
      setTxModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setTxModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              Your redemption was successful.
            </span>)
        setTxIsLoading(false);
        setRefresh((prev) => !prev);
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log("ERROR: ", e);
      setTxModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
    }
  }; 
  
  export const clearExpiredTx = async (
    setShowTxModal,
    setTxModalText,
    setTxIsLoading,
    wharfSession
  ) => {
    setShowTxModal(true);
    setTxModalText("Awaiting confirmation...");
  
    if (localStorage.getItem("wharf--session") == null) {
      setTxModalText(
        "You are not logged in. Click the wallet icon in the top menu"
      );
      return;
    }
  
    const session = wharfSession;

    
    let actions = [
        {
          account: DAPP_CONTRACT,
          name: "clearexpired",
          authorization: [session.permissionLevel],
          data: {
            user: session.actor
          },
        },
      ];
  
  
    try {
      const result = await session.transact(
        { actions: actions },
        defaultTxSettings
      );
      setTxIsLoading(true);
      setTxModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setTxModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              Your expired requests have been removed.
            </span>)
        setTxIsLoading(false);
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log("ERROR: ", e);
      setTxModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
    }
  }; 
  
  export const claimRewards = async (
    setRefresh,
    claimAs,
    amount,
    setShowTxModal,
    setTxModalText,
    setTxIsLoading,
    wharfSession
  ) => {
    setShowTxModal(true);
    setTxModalText("Awaiting confirmation...");
  
    if (localStorage.getItem("wharf--session") == null) {
      setTxModalText(
        "You are not logged in. Click the wallet icon in the top menu"
      );
      return;
    }
  
    const session = wharfSession;
    
    let actionName;
    let data = {
      user: session.actor
    };

    if(claimAs == "WAX"){
      actionName = "claimrewards"
    } else if(claimAs == "SWAX"){
      actionName = "claimswax"
    } else if(claimAs == "LSWAX"){
      actionName = "claimaslswax"

      data = {
        user: session.actor,
        minimum_output: `${Number(amount).toFixed(8)} LSWAX`
      }      
    } else {
      setTxModalText(
        "Unable to determine correct claim action"
      );
      return;      
    }

    

    
    let actions = [
        {
          account: DAPP_CONTRACT,
          name: actionName,
          authorization: [session.permissionLevel],
          data: data,
        },
      ];
  
  
    try {
      const result = await session.transact(
        { actions: actions },
        defaultTxSettings
      );
      setTxIsLoading(true);
      setTxModalText(PROCESSING_TX_TEXT);
      const timer = setTimeout(() => {
        setTxModalText(<span>
              <ModalSuccessCont>
              {success_svg}
              </ModalSuccessCont>
              Your claim was successful.
            </span>)
        setTxIsLoading(false);
        setRefresh((prev) => !prev);
      }, defaultSpinnerDuration);
      return () => clearTimeout(timer);
    } catch (e) {
      console.log("ERROR: ", e);
      setTxModalText(<span>
          <ModalErrorCont>
          {error_svg}
          </ModalErrorCont>
          {e.message}        
        </span>
          );
    }
  };   