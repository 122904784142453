import { useState } from "react";
import { DAPP_CONTRACT, POL_CONTRACT, TESTNET, currentUsername, mainnetApiList, testnetApiList } from "../../data/config";
import axios from 'axios';
import { isLoggedIn } from "../../data/functions/wallet_functions";

export const useGetMyShortTermRentals = () => {
    const [rentals, setRentals] = useState([]);
    const [loading, setLoading] = useState(true);

    let apiList = TESTNET ? testnetApiList : mainnetApiList;

    const getRentals = async (epoch_ids) => {
        setLoading(true);
        let rentalsArray = [];

        if (isLoggedIn()) {
            for (const epoch of epoch_ids) {
                let foundData = false;
                for (const api of apiList) {
                    try {
                        const res = await axios.post(`${api}/v1/chain/get_table_rows`, {
                            table: "renters",
                            scope: epoch,
                            code: DAPP_CONTRACT,
                            index_position: 2,
                            key_type: "name",
                            lower_bound: currentUsername,
                            upper_bound: currentUsername,
                            limit: 500,
                            json: true,
                        });

                        if (res.data.rows && res.data.rows.length > 0) {
                            const enhancedRows = res.data.rows.map(row => ({
                                ...row,
                                expires: parseInt(epoch) + (60 * 60 * 24 * 11)
                            }));
                            rentalsArray.push(...enhancedRows);
                            foundData = true;
                        }
                    } catch (error) {
                        console.log(error);
                    }
                    if (foundData) break;
                }
            }
        }
        setRentals(rentalsArray);
        setLoading(false);
    }

    return [rentals, getRentals, loading, setRentals];
}
