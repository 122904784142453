import styled from 'styled-components'
import { theme } from '../Styles'
import { waxfusionTheme } from '../Styles2024'

export const FooterLeft = styled.div`
    width: 30%;
    max-width: 30%;

    @media (max-width: 840px) {
        width: 100%;
        max-width: 100%;
    }

`

export const FooterRight = styled.div`
    display: flex;
    width: 70%;
    max-width: 70%;

    @media (max-width: 840px) {
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
    }

`

export const FooterColumn = styled.div`
    width: 33%;
    max-width: 33%;
    height: 100%;
    

    @media (max-width: 840px) {
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
        padding-left: 25px;
        height: auto;
    }

`

export const ColumnTitle = styled.div`
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: ${waxfusionTheme.darkBlue};
    padding-top: 2em;
    letter-spacing: 0.7px;
`

export const ColumnLinks = styled.div`
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: white;
    padding-left: 0.2em;
    padding-top: 1em;

`

export const FooterBrandText = styled.div`
    margin-top: 30px;
    width: 150px;
    max-width: 150px;
    height: auto;
    max-height: 75px;
    margin-left: 20px;
    margin-right: 15px;
    float: left;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.8px;
    color: ${waxfusionTheme.darkBlue};
    
`



export const FooterHref = styled.a`
    letter-spacing: 0.7px;
    transition: color 0.5s;
`


export const ModalContent = styled.div`

    color: white;
    font-size: 14px;
    a{
        color: ${theme.secondary}
    }
    

`

export const Spinner = styled.div`

    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    //mix-blend-mode: overlay;
    

`

export const SpinnerRed = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: whitesmoke;
    animation: rotate 0.6s linear infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}


`

export const SpinnerBlue = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid transparent;
    border-left-color: whitesmoke;
    animation: rotate 0.6s linear infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}

`

export const SpinnerGreen = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid transparent;
    border-right-color: whitesmoke;
    animation: rotate 0.6s linear infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}

`



export const NewRow = styled.div`
    flex-basis: 100%;
`