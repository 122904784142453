import { useState } from "react";
import { DAPP_CONTRACT, TESTNET, currentUsername, mainnetApiList, testnetApiList } from "../../data/config";
import axios from 'axios';
import { isLoggedIn } from "../../data/functions/wallet_functions";

export const useGetMyRedemptions = () => {
    
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);

    let apiList = TESTNET ? testnetApiList : mainnetApiList;

    const getRequests = async () => {
        setLoading(true);

        if(isLoggedIn()){
            for (const api of apiList) {
                try {
                    const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                        table: "rdmrequests",
                        scope: currentUsername,
                        code: DAPP_CONTRACT,
                        limit: 100,
                        json: true,
                    });
                    
                    if (res.data.rows) {
                        setRequests(res.data.rows);
                        break;
                    }
                } catch (error) {
                    console.log(error);
                }
            }   
        }

        setLoading(false);
    }

    return [requests, getRequests, loading]
}
