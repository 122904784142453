import { useState } from "react";
import { DAPP_CONTRACT, POL_CONTRACT, TESTNET, currentUsername, mainnetApiList, testnetApiList } from "../../data/config";
import axios from 'axios';
import { isLoggedIn } from "../../data/functions/wallet_functions";

export const useGetMyPolRentals = () => {
    
    const [rentals, setRentals] = useState([]);
    const [loading, setLoading] = useState(true);

    let apiList = TESTNET ? testnetApiList : mainnetApiList;

    const getRentals = async () => {
        setLoading(true);

        if(isLoggedIn()){
            for (const api of apiList) {
                try {
                    const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                        table: "renters",
                        scope: POL_CONTRACT,
                        code: POL_CONTRACT,
                        index_position: 2,
                        key_type: "name",
                        lower_bound: currentUsername,
                        upper_bound: currentUsername,
                        limit: 500,
                        json: true,
                    });
                    
                    if (res.data.rows) {
                        setRentals(res.data.rows);
                        break;
                    }
                } catch (error) {
                    console.log(error);
                }
            }   
        }

        setLoading(false);
    }

    return [rentals, getRentals, loading, setRentals]
}
